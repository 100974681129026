import axios from "axios";
import {
  SELLER_LIST_REQUEST,
  SELLER_LIST_SUCCESS,
  SELLER_LIST_FAIL,
  SELLER_DETAILS_REQUEST,
  SELLER_DETAILS_SUCCESS,
  SELLER_DETAILS_FAIL,
  SELLER_DELETE_SUCCESS,
  SELLER_DELETE_REQUEST,
  SELLER_DELETE_FAIL,
  SELLER_CREATE_REQUEST,
  SELLER_CREATE_SUCCESS,
  SELLER_CREATE_FAIL,
  SELLER_UPDATE_REQUEST,
  SELLER_UPDATE_SUCCESS,
  SELLER_UPDATE_FAIL,
  SELLER_CREATE_REVIEW_REQUEST,
  SELLER_CREATE_REVIEW_SUCCESS,
  SELLER_CREATE_REVIEW_FAIL,
  SELLER_TOP_REQUEST,
  SELLER_TOP_SUCCESS,
  SELLER_TOP_FAIL,
} from "../constants/sellerConstants";
import { logout } from "./userActions";

export const listAllSellers = () => async (dispatch) => {
  try {
    dispatch({ type: SELLER_LIST_REQUEST });

    const { data } = await axios.get(`/api/sellers/all`);

    dispatch({
      type: SELLER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SELLER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSellers =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: SELLER_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/sellers?keyword=${keyword}&pageNumber=${pageNumber}`
      );

      dispatch({
        type: SELLER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SELLER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSellerDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SELLER_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/sellers/${id}`);

    dispatch({
      type: SELLER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SELLER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteSeller = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SELLER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/sellers/${id}`, config);

    dispatch({
      type: SELLER_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SELLER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createSeller = (seller) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SELLER_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/sellers`, seller, config);

    dispatch({
      type: SELLER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SELLER_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateSeller = (seller) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SELLER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/sellers/${seller._id}`,
      seller,
      config
    );

    dispatch({
      type: SELLER_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: SELLER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SELLER_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const createSellerReview =
  (sellerId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_CREATE_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(`/api/sellers/${sellerId}/reviews`, review, config);

      dispatch({
        type: SELLER_CREATE_REVIEW_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: SELLER_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };

export const listTopSellers = () => async (dispatch) => {
  try {
    dispatch({ type: SELLER_TOP_REQUEST });

    const { data } = await axios.get(`/api/sellers/top`);

    dispatch({
      type: SELLER_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SELLER_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
