import axios from "axios";
import {
  SETTING_LIST_REQUEST,
  SETTING_LIST_SUCCESS,
  SETTING_LIST_FAIL,
  SETTING_UPDATE_REQUEST,
  SETTING_UPDATE_SUCCESS,
  SETTING_UPDATE_FAIL,
  SETTING_DETAILS_REQUEST,
  SETTING_DETAILS_SUCCESS,
  SETTING_DETAILS_FAIL,
} from "../constants/settingConstants";
import { logout } from "./userActions";

export const listSettings = () => async (dispatch) => {
  try {
    dispatch({ type: SETTING_LIST_REQUEST });

    const { data } = await axios.get(`/api/settings`);

    dispatch({
      type: SETTING_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SETTING_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSetting = (setting) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SETTING_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/settings/${setting._id}`,
      setting,
      config
    );

    dispatch({
      type: SETTING_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: SETTING_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SETTING_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const detailSetting = (id) => async (dispatch) => {
  try {
    dispatch({ type: SETTING_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/settings/${id}`);

    dispatch({
      type: SETTING_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SETTING_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
