import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import {
  listPackingMaterialDetails,
  updatePackingMaterial,
} from "../actions/packingMaterialActions";
import { PACKING_MATERIAL_UPDATE_RESET } from "../constants/packingMaterialConstants";

const PackingMaterialEditScreen = ({ match, history }) => {
  const packingMaterialId = match.params.id;

  const [material, setMaterial] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [defaultValue, setDefaultValue] = useState(false);

  const dispatch = useDispatch();

  const packingMaterialDetails = useSelector((state) => state.packingMaterialDetails);
  const { loading, error, packingMaterial } = packingMaterialDetails;

  const packingMaterialUpdate = useSelector((state) => state.packingMaterialUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = packingMaterialUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PACKING_MATERIAL_UPDATE_RESET });
      history.push("/packing-materials");
    } else {
      if (!packingMaterial.material || packingMaterial._id !== packingMaterialId) {
        dispatch(listPackingMaterialDetails(packingMaterialId));
      } else {
        setMaterial(packingMaterial.material)
        setDescription(packingMaterial.description)
        setPrice(packingMaterial.price)
        setTax(packingMaterial.tax)
        setDefaultValue(packingMaterial.defaultValue)
      }
    }
  }, [dispatch, history, packingMaterialId, packingMaterial, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updatePackingMaterial({
        _id: packingMaterialId,
        material,
        description,
        price,
        tax,
        defaultValue
      })
    );
  };

  return (
    <>
      <Link to="/packing-materials" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Packing Material</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="material">
              <Form.Label>Material</Form.Label>
              <Form.Control
                type="material"
                placeholder="Material"
                value={material}
                onChange={(e) => setMaterial(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="description"
                as={"textarea"}
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="price"
                value={price}
                placeholder="Price"
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="tax">
              <Form.Label>Tax</Form.Label>
              <Form.Control
                type="tax"
                value={tax}
                placeholder="Tax"
                onChange={(e) => setTax(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="defaultValue">
              <Form.Check
                type="checkbox"
                label="Default"
                checked={defaultValue}
                onChange={(e) => { setDefaultValue(e.target.checked) }}
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default PackingMaterialEditScreen;
