import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button, ListGroup, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { listGalleryDetails } from "../actions/galleryActions";
import { GALLERY_UPDATE_RESET } from "../constants/galleryConstants";
import { GALLERY_IMAGE_UPDATE_RESET } from "../constants/galleryImageConstants";
import {
  listGalleryImages,
  createGalleryImage,
  updateGalleryImage,
  deleteGalleryImage,
  updateGalleryImageSingle,
} from "../actions/galleryImageActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { arrayMove } from "../utils/arrayMove";

const GalleryGalleryScreen = ({ match, history }) => {
  const galleryId = match.params.id;
  const [galleryImageFiles, setGalleryImageFiles] = useState([]);
  const [imageDescription, setImageDescription] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    Promise.all(
      acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append("image", file);
        let response;
        try {
          response = await axios.post("/api/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } catch (err) {
          return err;
        }
        return response;
      })
    ).then((results) => {
      console.log(results);
      const fileData = results.map((file) => ({
        image: file.data,
        gallery: galleryId,
      }));
      dispatch(createGalleryImage(galleryId, fileData));
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const dispatch = useDispatch();

  const galleryImageList = useSelector((state) => state.galleryImageList);
  
  const {
    loading: loadingGalleryImage,
    error: errorGalleryImage,
    success: successGalleryImage,
    galleryImages,
  } = galleryImageList;

  const galleryImageCreate = useSelector((state) => state.galleryImageCreate);
  const {
    loading: loadingGalleryImageCreate,
    error: errorGalleryImageCreate,
    success: successGalleryImageCreate,
  } = galleryImageCreate;

  const galleryImageUpdate = useSelector((state) => state.galleryImageUpdate);
  const {
    loading: loadingGalleryImageSingle,
    error: errorGalleryImageSingle,
    success: successGalleryImageSingle,
  } = galleryImageUpdate;

  const galleryImageDelete = useSelector((state) => state.galleryImageDelete);
  const {
    loading: loadingGalleryImageDelete,
    error: errorGalleryImageDelete,
    success: successGalleryImageDelete,
  } = galleryImageDelete;

  const galleryDetails = useSelector((state) => state.galleryDetails);
  const { loading, error, gallery } = galleryDetails;

  useEffect(() => {
    dispatch(listGalleryDetails(galleryId));
    dispatch(listGalleryImages(galleryId));
  }, []);

  useEffect(() => {
    if (!loadingGalleryImageDelete && successGalleryImageDelete) {
      dispatch(listGalleryImages(galleryId));
    }
  }, [successGalleryImageDelete, loadingGalleryImageDelete]);

  useEffect(() => {
    if (!loadingGalleryImageCreate && successGalleryImageCreate) {
      dispatch(listGalleryImages(galleryId));
    }
  }, [successGalleryImageCreate, loadingGalleryImageCreate]);

  useEffect(() => {
    setGalleryImageFiles(galleryImages);
  }, [galleryImages]);

  useEffect(() => {
    if (successGalleryImage) {
      dispatch({ type: GALLERY_IMAGE_UPDATE_RESET });
    } else {
      if (!gallery.name || gallery._id !== galleryId) {
        dispatch(listGalleryImages(galleryId));
      }
    }
  }, [dispatch, history, galleryId, gallery, successGalleryImage]);

  const updateGalleryImageOrder = () => {
    const updatedGalleryImageFiles = galleryImageFiles.map(
      (galleryImage, index) => ({
        ...galleryImage,
        pOrder: index + 1,
      })
    );
    dispatch(updateGalleryImage({ galleryId, updatedGalleryImageFiles }));
  };

  const onDragEndAction = (param) => {
    const sourceIndex = param.source.index;
    const destinationIndex = param.destination?.index;
    if (destinationIndex) {
      setGalleryImageFiles(
        arrayMove(galleryImageFiles, sourceIndex, destinationIndex)
      );
    }
  };

  const removeImage = (e, galleryImageId) => {
    e.preventDefault();
    dispatch(deleteGalleryImage(galleryImageId));
  };

  const updateImageDescription = (inputId, event) => {
    event.preventDefault();
    const value = document.getElementById(`input-${inputId}`).value;
    dispatch(updateGalleryImageSingle(inputId, { name: value }));
  };

  return (
    <>
      <Link to="/gallerylist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        {loadingGalleryImage && <Loader />}
        {loadingGalleryImageCreate && <Message>Uploading Images...</Message>}
        {errorGalleryImageCreate && <Message>Error Uploading Images.</Message>}
        {loadingGalleryImageSingle && <Message>Updating...</Message>}
        {errorGalleryImageSingle && <Message>Oops, Error!</Message>}
        {loadingGalleryImageDelete && <Message>Deleting Image...</Message>}
        {errorGalleryImageDelete && <Message>Error Deleting Image.</Message>}
        {errorGalleryImage && (
          <Message variant="danger">{errorGalleryImage}</Message>
        )}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <h4>{gallery.name}</h4>
            <h6 className={"mt-1 pt-0"}>Add Images</h6>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>

            <div>
              <DragDropContext onDragEnd={(param) => onDragEndAction(param)}>
                <ListGroup className="mt-2">
                  <h2>Re-order images</h2>
                  <Droppable droppableId="droppable-1">
                    {(provided, _) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {galleryImageFiles.map((galleryImage, i) => (
                          <Draggable
                            key={galleryImage._id}
                            draggableId={"draggable-" + galleryImage._id}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <ListGroup.Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: snapshot.isDragging
                                    ? "0 0 .4rem #666"
                                    : "none",
                                }}
                              >
                                <div className="clearfix v-align-parent">
                                  <div
                                    className="left p2 mr1 v-align-child"
                                    {...provided.dragHandleProps}
                                  >
                                    <i className="fas fa-bars" />
                                  </div>
                                  <div className="left p2 mr1 v-align-child">
                                    <Image width={60} src={`${axios.defaults.baseURL}${galleryImage.image}`} />
                                  </div>
                                  <div className="v-align-child">
                                    <Form>
                                      <Form.Row className="align-items-center">
                                        <Col xs="auto">
                                          <Form.Control
                                            className="mb-2"
                                            id={`input-${galleryImage._id}`}
                                            placeholder="Image Description"
                                            defaultValue={galleryImage.name}
                                          />
                                        </Col>
                                        <Col xs="auto">
                                          <Button
                                            className="mb-2"
                                            onClick={(e) =>
                                              updateImageDescription(
                                                galleryImage._id,
                                                e
                                              )
                                            }
                                          >
                                            Update
                                          </Button>
                                        </Col>
                                        <Col xs="auto">
                                          <Button
                                            type="submit"
                                            className="mb-2 remove-photo"
                                            onClick={(e) =>
                                              removeImage(e, galleryImage._id)
                                            }
                                          >
                                            <i
                                              className={"fas fa-times-circle"}
                                            />
                                          </Button>
                                        </Col>
                                      </Form.Row>
                                    </Form>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <div>
                          <Button
                            onClick={updateGalleryImageOrder}
                            type="submit"
                            className="my-2 block mx-auto"
                          >
                            Update Order
                          </Button>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </ListGroup>
              </DragDropContext>
            </div>
          </>
        )}
      </FormContainer>
    </>
  );
};

export default GalleryGalleryScreen;
