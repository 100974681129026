import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
// import ProductScreen from "./screens/ProductScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductAddScreen from "./screens/ProductAddScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import ProductGalleryScreen from "./screens/ProductGalleryScreen";
// import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
// import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
// import ShippingScreen from "./screens/ShippingScreen";
// import PaymentScreen from "./screens/PaymentScreen";
// import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import OrderListScreen from "./screens/OrderListScreen";
import OrderEditScreen from "./screens/OrderEditScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import SellerListScreen from "./screens/SellerListScreen";
import SellerAddScreen from "./screens/SellerAddScreen";
import SellerEditScreen from "./screens/SellerEditScreen";
import SellerScreen from "./screens/SellerScreen";
import SellerGalleryScreen from "./screens/SellerGalleryScreen";
import CategoryAddScreen from "./screens/CategoryAddScreen";
import CategoryEditScreen from "./screens/CategoryEditScreen";
import CategoryListScreen from "./screens/CategoryListScreen";
import ShippingMethodAddScreen from "./screens/ShippingMethodAddScreen";
import ShippingMethodEditScreen from "./screens/ShippingMethodEditScreen";
import ShippingMethodListScreen from "./screens/ShippingMethodListScreen";
import PackingMaterialAddScreen from "./screens/PackingMaterialAddScreen";
import PackingMaterialEditScreen from "./screens/PackingMaterialEditScreen";
import PackingMaterialListScreen from "./screens/PackingMaterialListScreen";
import GalleryListScreen from "./screens/GalleryListScreen";
import GalleryAddScreen from "./screens/GalleryAddScreen";
import GalleryEditScreen from "./screens/GalleryEditScreen";
import GalleryScreen from "./screens/GalleryScreen";
import GalleryGalleryScreen from "./screens/GalleryGalleryScreen";
import UserViewScreen from "./screens/UserViewScreen";
import SettingListScreen from "./screens/SettingListScreen";
import SettingEditScreen from "./screens/SettingEditScreen";
import { APP_ROOT } from "./config/config";

const App = () => {
  return (
    <Router basename={`${APP_ROOT}`}>
      <Header />
      <main className="py-3">
        <Container>
          <Route path="/order/:id" exact component={OrderScreen} />
          <Route path="/order/:id/edit" component={OrderEditScreen} />
          {/* <Route path="/shipping" component={ShippingScreen} /> */}
          {/* <Route path="/payment" component={PaymentScreen} /> */}
          {/* <Route path="/placeorder" component={PlaceOrderScreen} /> */}
          <Route path="/login" component={LoginScreen} />
          {/* <Route path="/register" component={RegisterScreen} /> */}
          <Route path="/profile" component={ProfileScreen} />
          {/* <Route path="/product/:id" component={ProductScreen} /> */}
          {/* <Route path="/cart/:id?" component={CartScreen} /> */}
          <Route path="/userlist" component={UserListScreen} />
          <Route path="/user/:id/edit" component={UserEditScreen} />
          <Route path="/user/:id/view" component={UserViewScreen} />
          <Route
            path="/productlist"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/productlist/:pageNumber"
            component={ProductListScreen}
            exact
          />
          <Route path="/product/add" component={ProductAddScreen} />
          <Route path="/product/:id/edit" component={ProductEditScreen} />
          <Route
            path="/product/:id/gallery"
            component={ProductGalleryScreen}
          />
          <Route path="/selleradd" component={SellerAddScreen} />
          <Route path="/sellerlist" component={SellerListScreen} exact />
          <Route
            path="/sellerlist/:pageNumber"
            component={SellerListScreen}
            exact
          />
          <Route path="/seller/:id" component={SellerScreen} exact />
          <Route
            path="/seller/:id/edit"
            component={SellerEditScreen}
            exact
          />
          <Route
            path="/seller/:id/gallery"
            component={SellerGalleryScreen}
          />
          <Route path="/galleryadd" component={GalleryAddScreen} />
          <Route
            path="/gallerylist"
            component={GalleryListScreen}
            exact
          />
          <Route
            path="/gallerylist/:pageNumber"
            component={GalleryListScreen}
            exact
          />
          <Route path="/gallery/:id" component={GalleryScreen} exact />
          <Route
            path="/gallery/:id/edit"
            component={GalleryEditScreen}
            exact
          />
          <Route
            path="/gallery/:id/gallery"
            component={GalleryGalleryScreen}
          />
          <Route path="/categoryadd" component={CategoryAddScreen} />
          <Route
            path="/categorylist"
            component={CategoryListScreen}
            exact
          />
          <Route
            path="/categorylist/:pageNumber"
            component={CategoryListScreen}
            exact
          />
          {/*<Route path="/category/:id" component={CategoryScreen} exact />*/}
          <Route
            path="/category/:id/edit"
            component={CategoryEditScreen}
            exact
          />

          <Route
            path="/shipping-methods-add"
            component={ShippingMethodAddScreen}
          />
          <Route
            path="/shipping-methods"
            component={ShippingMethodListScreen}
            exact
          />
          <Route
            path="/shipping-methods/:pageNumber"
            component={ShippingMethodListScreen}
            exact
          />
          {/*<Route path="/shipping-methods/:id" component={ShippingMethodScreen} exact />*/}
          <Route
            path="/shipping-methods/:id/edit"
            component={ShippingMethodEditScreen}
            exact
          />

          <Route
            path="/packing-materials-add"
            component={PackingMaterialAddScreen}
          />
          <Route
            path="/packing-materials"
            component={PackingMaterialListScreen}
            exact
          />
          <Route
            path="/packing-materials/:pageNumber"
            component={PackingMaterialListScreen}
            exact
          />
          {/*<Route path="/packing-materials/:id" component={PackingMaterialScreen} exact />*/}
          <Route
            path="/packing-materials/:id/edit"
            component={PackingMaterialEditScreen}
            exact
          />

          <Route path="/orderlist" component={OrderListScreen} />
          <Route path="/search/:keyword" component={HomeScreen} exact />
          <Route path="/page/:pageNumber" component={HomeScreen} exact />
          <Route
            path="/search/:keyword/page/:pageNumber"
            component={HomeScreen}
            exact
          />
          <Route path="/settings" component={SettingListScreen} exact />
          <Route
            path="/settings/:id/edit"
            component={SettingEditScreen}
            exact
          />
          <Route path="/" component={HomeScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
