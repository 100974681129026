import {
  SETTING_LIST_REQUEST,
  SETTING_LIST_SUCCESS,
  SETTING_LIST_FAIL,
  SETTING_UPDATE_REQUEST,
  SETTING_UPDATE_SUCCESS,
  SETTING_UPDATE_FAIL,
  SETTING_UPDATE_RESET,
  SETTING_DETAILS_REQUEST,
  SETTING_DETAILS_SUCCESS,
  SETTING_DETAILS_FAIL,
} from "../constants/settingConstants";

export const settingListReducer = (state = { settings: [] }, action) => {
  switch (action.type) {
    case SETTING_LIST_REQUEST:
      return { loading: true, settings: [] };
    case SETTING_LIST_SUCCESS:
      return {
        loading: false,
        settings: action.payload.settings,
      };
    case SETTING_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const settingUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTING_UPDATE_REQUEST:
      return { loading: true };
    case SETTING_UPDATE_SUCCESS:
      return { loading: false, success: true, setting: action.payload };
    case SETTING_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SETTING_UPDATE_RESET:
      return { setting: {} };
    default:
      return state;
  }
};

export const settingDetailsReducer = (state = { setting: {} }, action) => {
  switch (action.type) {
    case SETTING_DETAILS_REQUEST:
      return { ...state, loading: true };
    case SETTING_DETAILS_SUCCESS:
      return { loading: false, setting: action.payload };
    case SETTING_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
