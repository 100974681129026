import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { getUserDetails, updateUser } from "../actions/userActions";
import { USER_UPDATE_RESET } from "../constants/userConstants";
import { listOrders } from "../actions/orderActions";
import { customOrderId } from "../utils/customOrderId";
import { omDateFormat } from "../utils/omDateFormat";

const UserViewScreen = ({ match, history }) => {
  const userId = match.params.id;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const orderList = useSelector((state) => state.orderList);
  const { loadingOd, errorOd, orders } = orderList;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      history.push("/userlist");
    } else {
      if (!user.name || user._id !== userId) {
        dispatch(getUserDetails(userId));
        dispatch(listOrders());
      } else {
        setName(user.name);
        setEmail(user.email);
        setIsAdmin(user.isAdmin);
      }
    }
  }, [dispatch, history, userId, user, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUser({ _id: userId, name, email, isAdmin }));
  };

  return (
    <Row>
      <Col md={3}>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <br />
              {user.name}
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <br />
              {user.email}
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <br />
              {user.addresses && user.addresses.map((address, index) => {
                return <Fragment key={address._id}><h5>Address {index+1}</h5><p><strong>{address.name}</strong>, {address.address}, {address.city}, {address.country}, {address.zip}</p><hr /><br /></Fragment>
              })}
            </Form.Group>
          </Form>
        )}
      </Col>
      <Col md={9}>
        <h2>Orders</h2>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loadingOd ? (
          <Loader />
        ) : errorOd ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders && orders.map((order) => {
                if (order.user && order.user._id === user._id) {
                  return (
                    <tr key={order._id}>
                      <td>{customOrderId(order.createdAt)}</td>
                      <td>{omDateFormat(order.createdAt)}</td>
                      <td>€{order.totalPrice}</td>
                      <td>
                        {order.isPaid ? (
                          omDateFormat(order.paidAt)
                        ) : (
                          <i className="fas fa-times" style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td>
                        {order.isDelivered ? (
                          omDateFormat(order.deliveredAt)
                        ) : (
                          <i className="fas fa-times" style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td>
                        <LinkContainer to={`/order/${order._id}`}>
                          <Button variant="light" className="btn-sm">
                            Details
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  )
                }
              })}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
};

export default UserViewScreen;
