import axios from "axios";
import {
  SELLER_IMAGE_LIST_REQUEST,
  SELLER_IMAGE_LIST_SUCCESS,
  SELLER_IMAGE_LIST_FAIL,
  SELLER_IMAGE_DETAILS_REQUEST,
  SELLER_IMAGE_DETAILS_SUCCESS,
  SELLER_IMAGE_DETAILS_FAIL,
  SELLER_IMAGE_DELETE_SUCCESS,
  SELLER_IMAGE_DELETE_REQUEST,
  SELLER_IMAGE_DELETE_FAIL,
  SELLER_IMAGE_CREATE_REQUEST,
  SELLER_IMAGE_CREATE_SUCCESS,
  SELLER_IMAGE_CREATE_FAIL,
  SELLER_IMAGE_UPDATE_REQUEST,
  SELLER_IMAGE_UPDATE_SUCCESS,
  SELLER_IMAGE_UPDATE_FAIL,
  SELLER_IMAGE_CREATE_REVIEW_REQUEST,
  SELLER_IMAGE_CREATE_REVIEW_SUCCESS,
  SELLER_IMAGE_CREATE_REVIEW_FAIL,
  SELLER_IMAGE_TOP_REQUEST,
  SELLER_IMAGE_TOP_SUCCESS,
  SELLER_IMAGE_TOP_FAIL,
} from "../constants/sellerImageConstants";
import { logout } from "./userActions";
import { wrapMergePropsFunc } from "react-redux/lib/connect/mergeProps";

export const listSellerImages =
  (seller = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: SELLER_IMAGE_LIST_REQUEST });

      const { data } = await axios.get(`/api/seller-images?seller=${seller}`);

      dispatch({
        type: SELLER_IMAGE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SELLER_IMAGE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSellerImageDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SELLER_IMAGE_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/seller-images/?seller=${id}`);

    dispatch({
      type: SELLER_IMAGE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SELLER_IMAGE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteSellerImage = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SELLER_IMAGE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/seller-images/${id}`, config);

    dispatch({
      type: SELLER_IMAGE_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SELLER_IMAGE_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createSellerImage =
  (sellerId, sellerImage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_IMAGE_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/seller-images/${sellerId}`,
        sellerImage,
        config
      );

      dispatch({
        type: SELLER_IMAGE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: SELLER_IMAGE_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const updateSellerImage =
  (sellerImageData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_IMAGE_UPDATE_REQUEST,
      });

      const { sellerId, updatedSellerImageFiles } = sellerImageData;

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/seller-images/${sellerId}`,
        { images: updatedSellerImageFiles },
        config
      );

      dispatch({
        type: SELLER_IMAGE_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: SELLER_IMAGE_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "err");
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: SELLER_IMAGE_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const updateSellerImageSingle =
  (sellerId, sellerImageData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_IMAGE_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `/api/seller-images/${sellerId}`,
        { image: sellerImageData },
        config
      );

      dispatch({
        type: SELLER_IMAGE_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: SELLER_IMAGE_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "err");
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: SELLER_IMAGE_UPDATE_FAIL,
        payload: message,
      });
    }
  };
