import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { createCategory } from "../actions/categoryActions";

const CategoryAddScreen = ({ match, history }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const categoryCreate = useSelector((state) => state.categoryCreate);
  const {
    loading: loadingAdd,
    error: errorAdd,
    success: successAdd,
  } = categoryCreate;

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.log(error);
      setUploading(false);
    }
  };

  useEffect(() => {
    if (successAdd) {
      setTimeout(() => {
        history.push("/categorylist");
      }, 3000);
    }
  });

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createCategory({
        name,
        description,
        image,
      })
    );
  };

  return (
    <>
      <Link to="/categorylist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Add Category</h1>
        {loadingAdd && <Loader />}
        {!loadingAdd && successAdd && (
          <Message variant="success">Success!</Message>
        )}
        {errorAdd && <Message variant="danger">{errorAdd}</Message>}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="name"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="description"
              as={"textarea"}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="image">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="text"
              disabled={true}
              placeholder="Selected image"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
            <Form.File
              id="image-file"
              label="Choose File"
              custom
              onChange={uploadFileHandler}
            />
            {uploading && <Loader />}
          </Form.Group>
          <Button type="submit" variant="primary">
            Add
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default CategoryAddScreen;
