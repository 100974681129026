import axios from "axios";
import {
  PRODUCT_IMAGE_LIST_REQUEST,
  PRODUCT_IMAGE_LIST_SUCCESS,
  PRODUCT_IMAGE_LIST_FAIL,
  PRODUCT_IMAGE_DETAILS_REQUEST,
  PRODUCT_IMAGE_DETAILS_SUCCESS,
  PRODUCT_IMAGE_DETAILS_FAIL,
  PRODUCT_IMAGE_DELETE_SUCCESS,
  PRODUCT_IMAGE_DELETE_REQUEST,
  PRODUCT_IMAGE_DELETE_FAIL,
  PRODUCT_IMAGE_CREATE_REQUEST,
  PRODUCT_IMAGE_CREATE_SUCCESS,
  PRODUCT_IMAGE_CREATE_FAIL,
  PRODUCT_IMAGE_UPDATE_REQUEST,
  PRODUCT_IMAGE_UPDATE_SUCCESS,
  PRODUCT_IMAGE_UPDATE_FAIL,
  PRODUCT_IMAGE_CREATE_REVIEW_REQUEST,
  PRODUCT_IMAGE_CREATE_REVIEW_SUCCESS,
  PRODUCT_IMAGE_CREATE_REVIEW_FAIL,
  PRODUCT_IMAGE_TOP_REQUEST,
  PRODUCT_IMAGE_TOP_SUCCESS,
  PRODUCT_IMAGE_TOP_FAIL,
} from "../constants/productImageConstants";
import { logout } from "./userActions";
import { wrapMergePropsFunc } from "react-redux/lib/connect/mergeProps";

export const listProductImages =
  (product = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_IMAGE_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/product-images?product=${product}`
      );

      dispatch({
        type: PRODUCT_IMAGE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_IMAGE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listProductImageDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_IMAGE_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/product-images/?product=${id}`);

    dispatch({
      type: PRODUCT_IMAGE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_IMAGE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteProductImage = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_IMAGE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/product-images/${id}`, config);

    dispatch({
      type: PRODUCT_IMAGE_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PRODUCT_IMAGE_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createProductImage =
  (productId, productImage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_IMAGE_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/product-images/${productId}`,
        productImage,
        config
      );

      dispatch({
        type: PRODUCT_IMAGE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: PRODUCT_IMAGE_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const updateProductImage =
  (productImageData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_IMAGE_UPDATE_REQUEST,
      });

      const { productId, updatedProductImageFiles } = productImageData;

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/product-images/${productId}`,
        { images: updatedProductImageFiles },
        config
      );

      dispatch({
        type: PRODUCT_IMAGE_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: PRODUCT_IMAGE_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "err");
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: PRODUCT_IMAGE_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const updateProductImageSingle =
  (productId, productImageData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_IMAGE_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `/api/product-images/${productId}`,
        { image: productImageData },
        config
      );

      dispatch({
        type: PRODUCT_IMAGE_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: PRODUCT_IMAGE_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "err");
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: PRODUCT_IMAGE_UPDATE_FAIL,
        payload: message,
      });
    }
  };
