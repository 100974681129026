import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
} from "./reducers/productReducers";
import {
  sellerListReducer,
  sellerDetailsReducer,
  sellerDeleteReducer,
  sellerCreateReducer,
  sellerUpdateReducer,
} from "./reducers/sellerReducers";
import {
  galleryListReducer,
  galleryDetailsReducer,
  galleryDeleteReducer,
  galleryCreateReducer,
  galleryUpdateReducer,
} from "./reducers/galleryReducers";
import {
  categoryListReducer,
  categoryDetailsReducer,
  categoryDeleteReducer,
  categoryCreateReducer,
  categoryUpdateReducer,
} from "./reducers/categoryReducers";
import {
  shippingMethodListReducer,
  shippingMethodDetailsReducer,
  shippingMethodDeleteReducer,
  shippingMethodCreateReducer,
  shippingMethodUpdateReducer,
} from "./reducers/shippingMethodReducers";
import {
  packingMaterialListReducer,
  packingMaterialDetailsReducer,
  packingMaterialDeleteReducer,
  packingMaterialCreateReducer,
  packingMaterialUpdateReducer,
} from "./reducers/packingMaterialReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from "./reducers/userReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
  orderInvoiceReducer,
  orderPackReducer,
  orderSendReducer,
  orderFinishReducer,
  orderUpdateReducer,
  orderCancelReducer,
  orderAddProductReducer,
} from "./reducers/orderReducers";
import {
  productImageCreateReducer,
  productImageDeleteReducer,
  productImageDetailsReducer,
  productImageListReducer,
  productImageUpdateReducer,
} from "./reducers/productImageReducers";
import {
  sellerImageCreateReducer,
  sellerImageDeleteReducer,
  sellerImageDetailsReducer,
  sellerImageListReducer,
  sellerImageUpdateReducer,
} from "./reducers/sellerImageReducers";
import {
  galleryImageCreateReducer,
  galleryImageDeleteReducer,
  galleryImageDetailsReducer,
  galleryImageListReducer,
  galleryImageUpdateReducer,
} from "./reducers/galleryImageReducers";

import {
  settingListReducer,
  settingUpdateReducer,
  settingDetailsReducer,
} from "./reducers/settingReducers";

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  productImageList: productImageListReducer,
  productImageDetails: productImageDetailsReducer,
  productImageDelete: productImageDeleteReducer,
  productImageCreate: productImageCreateReducer,
  productImageUpdate: productImageUpdateReducer,
  sellerImageCreate: sellerImageCreateReducer,
  sellerImageDelete: sellerImageDeleteReducer,
  sellerImageDetails: sellerImageDetailsReducer,
  sellerImageList: sellerImageListReducer,
  sellerImageUpdate: sellerImageUpdateReducer,
  sellerList: sellerListReducer,
  sellerDetails: sellerDetailsReducer,
  sellerDelete: sellerDeleteReducer,
  sellerCreate: sellerCreateReducer,
  sellerUpdate: sellerUpdateReducer,
  categoryList: categoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryDelete: categoryDeleteReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
  shippingMethodList: shippingMethodListReducer,
  shippingMethodDetails: shippingMethodDetailsReducer,
  shippingMethodDelete: shippingMethodDeleteReducer,
  shippingMethodCreate: shippingMethodCreateReducer,
  shippingMethodUpdate: shippingMethodUpdateReducer,
  packingMaterialList: packingMaterialListReducer,
  packingMaterialDetails: packingMaterialDetailsReducer,
  packingMaterialDelete: packingMaterialDeleteReducer,
  packingMaterialCreate: packingMaterialCreateReducer,
  packingMaterialUpdate: packingMaterialUpdateReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderUpdate: orderUpdateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderInvoice: orderInvoiceReducer,
  orderPack: orderPackReducer,
  orderSend: orderSendReducer,
  orderCancel: orderCancelReducer,
  orderFinish: orderFinishReducer,
  orderAddProduct: orderAddProductReducer,
  galleryImageCreate: galleryImageCreateReducer,
  galleryImageDelete: galleryImageDeleteReducer,
  galleryImageDetails: galleryImageDetailsReducer,
  galleryImageList: galleryImageListReducer,
  galleryImageUpdate: galleryImageUpdateReducer,
  galleryList: galleryListReducer,
  galleryDetails: galleryDetailsReducer,
  galleryDelete: galleryDeleteReducer,
  galleryCreate: galleryCreateReducer,
  galleryUpdate: galleryUpdateReducer,
  settingList: settingListReducer,
  settingUpdate: settingUpdateReducer,
  settingDetails: settingDetailsReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
