import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { listSellerDetails, updateSeller } from "../actions/sellerActions";
import { SELLER_UPDATE_RESET } from "../constants/sellerConstants";

const SellerEditScreen = ({ match, history }) => {
  const sellerId = match.params.id;

  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sAlias, setSalias] = useState("");
  const [taxes, setTaxes] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [notification, setNotification] = useState("");

  const dispatch = useDispatch();

  const sellerDetails = useSelector((state) => state.sellerDetails);
  const { loading, error, seller } = sellerDetails;

  const sellerUpdate = useSelector((state) => state.sellerUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = sellerUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SELLER_UPDATE_RESET });
      history.push("/sellerlist");
    } else {
      if (!seller.fName || seller._id !== sellerId) {
        dispatch(listSellerDetails(sellerId));
      } else {
        setFname(seller.fName);
        setLname(seller.lName);
        setAddress(seller.address);
        setCity(seller.city);
        setZip(seller.zip);
        setEmail(seller.email);
        setPhone(seller.phone);
        setSalias(seller.sAlias);
        setTaxes(seller.taxes);
        setTaxNumber(seller.taxNumber);
        setNotification(seller.notification);
      }
    }
  }, [dispatch, history, sellerId, seller, successUpdate]);

  /*  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };*/

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateSeller({
        _id: sellerId,
        fName,
        lName,
        address,
        city,
        zip,
        email,
        phone,
        sAlias,
        taxes,
        taxNumber,
        notification,
      })
    );
  };

  return (
    <>
      <Link to="/sellerlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Seller</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="fName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="fname"
                placeholder="Enter First Name"
                value={fName}
                onChange={(e) => setFname(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="lName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="lname"
                placeholder="Enter First Name"
                value={lName}
                onChange={(e) => setLname(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                type="address"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="city"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="zip">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="zip"
                placeholder="Zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="emali">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="alias">
              <Form.Label>Alias</Form.Label>
              <Form.Control
                type="sAlias"
                placeholder="Alias"
                value={sAlias}
                onChange={(e) => setSalias(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="alias">
              <Form.Label>Taxes</Form.Label>
              <Form.Control
                type="taxes"
                placeholder="Taxes"
                value={taxes}
                onChange={(e) => setTaxes(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="taxNumber">
              <Form.Label>Tax Number</Form.Label>
              <Form.Control
                type="taxNumber"
                placeholder="Tax Number"
                value={taxNumber}
                onChange={(e) => setTaxNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="notification">
              <Form.Label>Notification Method</Form.Label>
              <Form.Control
                as="select"
                type="notification"
                value={notification}
                onChange={(e) => setNotification(e.target.value)}
              >
                <option>Select</option>
                <option value="EMAIL">Email</option>
                <option value="PHONE">Phone</option>
              </Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default SellerEditScreen;
