import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { listOrders } from "../actions/orderActions";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HomeScreen = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders());
    } else {
      history.push("/login");
    }
  }, [history]);
  let active = 0;
  let completed = 0;
  let cancelled = 0;
  orders &&
    orders.forEach((order) => {
      if (order.status) {
        if (order.isFinished) {
          completed = completed + 1;
        } else {
          active = active + 1;
        }
      } else {
        cancelled = cancelled + 1;
      }
    });
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Order Summary",
      },
    },
  };
  const labels = ["Active", "Completed", "Cancelled"];
  const data = {
    labels,
    datasets: [
      {
        label: "Order Status",
        data: [active, completed, cancelled],
        backgroundColor: "#588",
      },
    ],
  };
  return (
    <Row>
      <Col md={12}>
        <Bar options={options} data={data} />;
      </Col>
    </Row>
  );
};

export default HomeScreen;
