import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { createGallery, listGalleryDetails, updateGallery } from "../actions/galleryActions";

const GalleryAddScreen = ({ history }) => {

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  // const galleryDetails = useSelector((state) => state.galleryDetails);
  // const { loading, error, gallery } = galleryDetails;

  // const galleryUpdate = useSelector((state) => state.galleryUpdate);
  // const {
  //   loading: loadingUpdate,
  //   error: errorUpdate,
  //   success: successUpdate,
  // } = galleryUpdate;

  const galleryCreate = useSelector((state) => state.galleryCreate);
  const {
    loading: loadingAdd,
    error: errorAdd,
    success: successAdd,
  } = galleryCreate;

  useEffect(() => {
    if (successAdd) {
      setTimeout(() => {
        history.push("/gallerylist");
      }, 3000);
    }
  });

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createGallery({
        title,
        description,
      })
    );
  };

  return (
    <>
      <Link to="/gallerylist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Add Gallery</h1>
        {errorAdd && <Message variant="danger">{errorAdd}</Message>}
        {loadingAdd ? (
          <Loader />
        ) : errorAdd ? (
          <Message variant="danger">{errorAdd}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="title"
                placeholder="Enter First Name"
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                type="description"
                placeholder="description"
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="submit" variant="primary">
              Create
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default GalleryAddScreen;
