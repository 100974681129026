import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import {
  listSellers,
  deleteSeller,
  createSeller,
} from "../actions/sellerActions";
import { SELLER_CREATE_RESET } from "../constants/sellerConstants";

const SellerListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const sellerList = useSelector((state) => state.sellerList);
  const { loading, error, sellers, page, pages } = sellerList;

  const sellerDelete = useSelector((state) => state.sellerDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = sellerDelete;

  /*const sellerCreate = useSelector((state) => state.sellerCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    seller: createdSeller,
  } = sellerCreate;*/

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: SELLER_CREATE_RESET });

    if (!userInfo || !userInfo.isAdmin) {
      history.push("/login");
    }

    // if (successCreate) {
    //   history.push(`/seller/${createdSeller._id}/edit`);
    // } else {
    dispatch(listSellers("", pageNumber));
    // }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    // successCreate,
    // createdSeller,
    pageNumber,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteSeller(id));
    }
  };

  const createSellerHandler = () => {
    history.push("/selleradd");
  };

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>Sellers</h1>
        </Col>
        <Col className="text-right">
          <Button className="my-3" onClick={createSellerHandler}>
            <i className="fas fa-plus"></i> Create Seller
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>ADDRESS</th>
                <th>E-MAIL</th>
                <th>PHONE</th>
                <th>ALIAS</th>
                <th>TAXES</th>
                <th>TAX NUMBER</th>
                <th>NOTIFICATION</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sellers.map((seller) => (
                <tr key={seller._id}>
                  <td>{seller.suid}</td>
                  <td>
                    {seller.fName} {seller.lName}
                  </td>
                  <td>{seller.address}</td>
                  <td>{seller.email}</td>
                  <td>{seller.phone}</td>
                  <td>{seller.sAlias}</td>
                  <td>{seller.taxes}</td>
                  <td>{seller.taxNumber}</td>
                  <td>{seller.notification}</td>
                  <td>
                    <LinkContainer to={`/seller/${seller._id}`}>
                      <Button variant="success" className="btn-sm">
                        <i className="fas fa-eye"></i>
                      </Button>
                    </LinkContainer>
                    <LinkContainer to={`/seller/${seller._id}/gallery`}>
                      <Button variant="info" className="btn-sm">
                        <i className="fas fa-image"></i>
                      </Button>
                    </LinkContainer>
                    <LinkContainer to={`/seller/${seller._id}/edit`}>
                      <Button variant="warning" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(seller._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            slug="sellerlist"
            pages={pages}
            page={page}
            isAdmin={true}
          />
        </>
      )}
    </>
  );
};

export default SellerListScreen;
