import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button, ListGroup, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { listSellerDetails } from "../actions/sellerActions";
import { SELLER_UPDATE_RESET } from "../constants/sellerConstants";
import { SELLER_IMAGE_UPDATE_RESET } from "../constants/sellerImageConstants";
import {
  listSellerImages,
  createSellerImage,
  updateSellerImage,
  deleteSellerImage,
  updateSellerImageSingle,
} from "../actions/sellerImageActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { arrayMove } from "../utils/arrayMove";

const SellerGalleryScreen = ({ match, history }) => {
  const sellerId = match.params.id;
  const [sellerImageFiles, setSellerImageFiles] = useState([]);
  const [imageDescription, setImageDescription] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    Promise.all(
      acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append("image", file);
        let response;
        try {
          response = await axios.post("/api/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } catch (err) {
          return err;
        }
        return response;
      })
    ).then((results) => {
      const fileData = results.map((file) => ({
        image: file.data,
        seller: sellerId,
      }));
      dispatch(createSellerImage(sellerId, fileData));
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const dispatch = useDispatch();

  const sellerImageList = useSelector((state) => state.sellerImageList);
  const {
    loading: loadingSellerImage,
    error: errorSellerImage,
    success: successSellerImage,
    sellerImages,
  } = sellerImageList;

  const sellerImageCreate = useSelector((state) => state.sellerImageCreate);
  const {
    loading: loadingSellerImageCreate,
    error: errorSellerImageCreate,
    success: successSellerImageCreate,
  } = sellerImageCreate;

  const sellerImageUpdate = useSelector((state) => state.sellerImageUpdate);
  const {
    loading: loadingSellerImageSingle,
    error: errorSellerImageSingle,
    success: successSellerImageSingle,
  } = sellerImageUpdate;

  const sellerImageDelete = useSelector((state) => state.sellerImageDelete);
  const {
    loading: loadingSellerImageDelete,
    error: errorSellerImageDelete,
    success: successSellerImageDelete,
  } = sellerImageDelete;

  const sellerDetails = useSelector((state) => state.sellerDetails);
  const { loading, error, seller } = sellerDetails;

  useEffect(() => {
    dispatch(listSellerDetails(sellerId));
    dispatch(listSellerImages(sellerId));
  }, []);

  useEffect(() => {
    if (!loadingSellerImageDelete && successSellerImageDelete) {
      dispatch(listSellerImages(sellerId));
    }
  }, [successSellerImageDelete, loadingSellerImageDelete]);

  useEffect(() => {
    if (!loadingSellerImageCreate && successSellerImageCreate) {
      dispatch(listSellerImages(sellerId));
    }
  }, [successSellerImageCreate, loadingSellerImageCreate]);

  useEffect(() => {
    setSellerImageFiles(sellerImages);
  }, [sellerImages]);

  useEffect(() => {
    if (successSellerImage) {
      dispatch({ type: SELLER_IMAGE_UPDATE_RESET });
    } else {
      if (!seller.name || seller._id !== sellerId) {
        dispatch(listSellerImages(sellerId));
      }
    }
  }, [dispatch, history, sellerId, seller, successSellerImage]);

  const updateSellerImageOrder = () => {
    const updatedSellerImageFiles = sellerImageFiles.map(
      (sellerImage, index) => ({
        ...sellerImage,
        pOrder: index + 1,
      })
    );
    dispatch(updateSellerImage({ sellerId, updatedSellerImageFiles }));
  };

  const onDragEndAction = (param) => {
    const sourceIndex = param.source.index;
    const destinationIndex = param.destination?.index;
    if (destinationIndex) {
      setSellerImageFiles(
        arrayMove(sellerImageFiles, sourceIndex, destinationIndex)
      );
    }
  };

  const removeImage = (e, sellerImageId) => {
    e.preventDefault();
    dispatch(deleteSellerImage(sellerImageId));
  };

  const updateImageDescription = (inputId, event) => {
    event.preventDefault();
    const value = document.getElementById(`input-${inputId}`).value;
    dispatch(updateSellerImageSingle(inputId, { name: value }));
  };

  return (
    <>
      <Link to="/sellerlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        {loadingSellerImage && <Loader />}
        {loadingSellerImageCreate && <Message>Uploading Images...</Message>}
        {errorSellerImageCreate && <Message>Error Uploading Images.</Message>}
        {loadingSellerImageSingle && <Message>Updating...</Message>}
        {errorSellerImageSingle && <Message>Oops, Error!</Message>}
        {loadingSellerImageDelete && <Message>Deleting Image...</Message>}
        {errorSellerImageDelete && <Message>Error Deleting Image.</Message>}
        {errorSellerImage && (
          <Message variant="danger">{errorSellerImage}</Message>
        )}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <h4>{seller.name}</h4>
            <h6 className={"mt-1 pt-0"}>Add Images</h6>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>

            <div>
              <DragDropContext onDragEnd={(param) => onDragEndAction(param)}>
                <ListGroup className="mt-2">
                  <h2>Re-order images</h2>
                  <Droppable droppableId="droppable-1">
                    {(provided, _) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {sellerImageFiles.map((sellerImage, i) => (
                          <Draggable
                            key={sellerImage._id}
                            draggableId={"draggable-" + sellerImage._id}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <ListGroup.Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: snapshot.isDragging
                                    ? "0 0 .4rem #666"
                                    : "none",
                                }}
                              >
                                <div className="clearfix v-align-parent">
                                  <div
                                    className="left p2 mr1 v-align-child"
                                    {...provided.dragHandleProps}
                                  >
                                    <i className="fas fa-bars" />
                                  </div>
                                  <div className="left p2 mr1 v-align-child">
                                    <Image width={60} src={`${axios.defaults.baseURL}${sellerImage.image}`} />
                                  </div>
                                  <div className="v-align-child">
                                    <Form>
                                      <Form.Row className="align-items-center">
                                        <Col xs="auto">
                                          <Form.Control
                                            className="mb-2"
                                            id={`input-${sellerImage._id}`}
                                            placeholder="Image Description"
                                            defaultValue={sellerImage.name}
                                          />
                                        </Col>
                                        <Col xs="auto">
                                          <Button
                                            className="mb-2"
                                            onClick={(e) =>
                                              updateImageDescription(
                                                sellerImage._id,
                                                e
                                              )
                                            }
                                          >
                                            Update
                                          </Button>
                                        </Col>
                                        <Col xs="auto">
                                          <Button
                                            type="submit"
                                            className="mb-2 remove-photo"
                                            onClick={(e) =>
                                              removeImage(e, sellerImage._id)
                                            }
                                          >
                                            <i
                                              className={"fas fa-times-circle"}
                                            />
                                          </Button>
                                        </Col>
                                      </Form.Row>
                                    </Form>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <div>
                          <Button
                            onClick={updateSellerImageOrder}
                            type="submit"
                            className="my-2 block mx-auto"
                          >
                            Update Order
                          </Button>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </ListGroup>
              </DragDropContext>
            </div>
          </>
        )}
      </FormContainer>
    </>
  );
};

export default SellerGalleryScreen;
