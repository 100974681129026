import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { createPackingMaterial } from "../actions/packingMaterialActions";

const PackingMaterialAddScreen = ({ match, history }) => {
  const [material, setMaterial] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [defaultValue, setDefaultValue] = useState(false);

  const dispatch = useDispatch();

  const packingMaterialCreate = useSelector((state) => state.packingMaterialCreate);
  const {
    loading: loadingAdd,
    error: errorAdd,
    success: successAdd,
  } = packingMaterialCreate;

  useEffect(() => {
    if (successAdd) {
      setTimeout(() => {
        history.push("/packing-materials");
      }, 3000);
    }
  });

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createPackingMaterial({
        material,
        description,
        price,
        tax,
        defaultValue
      })
    );
  };

  return (
    <>
      <Link to="/packing-materials" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Add Packing Material</h1>
        {loadingAdd && <Loader />}
        {!loadingAdd && successAdd && (
          <Message variant="success">Success!</Message>
        )}
        {errorAdd && <Message variant="danger">{errorAdd}</Message>}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="material">
            <Form.Label>Material</Form.Label>
            <Form.Control
              type="material"
              placeholder="Material"
              onChange={(e) => setMaterial(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="description"
              as={"textarea"}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="price"
              placeholder="Price"
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="tax">
            <Form.Label>Tax</Form.Label>
            <Form.Control
              type="tax"
              placeholder="Tax"
              onChange={(e) => setTax(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="defaultValue">
            <Form.Check
              type="checkbox"
              label="Default"
              onChange={(e) => { setDefaultValue(e.target.checked) }}
            />
          </Form.Group>
          <Button type="submit" variant="primary">
            Add
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default PackingMaterialAddScreen;
