import axios from "axios";
import {
  PACKING_MATERIAL_LIST_REQUEST,
  PACKING_MATERIAL_LIST_SUCCESS,
  PACKING_MATERIAL_LIST_FAIL,
  PACKING_MATERIAL_DETAILS_REQUEST,
  PACKING_MATERIAL_DETAILS_SUCCESS,
  PACKING_MATERIAL_DETAILS_FAIL,
  PACKING_MATERIAL_DELETE_SUCCESS,
  PACKING_MATERIAL_DELETE_REQUEST,
  PACKING_MATERIAL_DELETE_FAIL,
  PACKING_MATERIAL_CREATE_REQUEST,
  PACKING_MATERIAL_CREATE_SUCCESS,
  PACKING_MATERIAL_CREATE_FAIL,
  PACKING_MATERIAL_UPDATE_REQUEST,
  PACKING_MATERIAL_UPDATE_SUCCESS,
  PACKING_MATERIAL_UPDATE_FAIL,
  PACKING_MATERIAL_CREATE_REVIEW_REQUEST,
  PACKING_MATERIAL_CREATE_REVIEW_SUCCESS,
  PACKING_MATERIAL_CREATE_REVIEW_FAIL,
  PACKING_MATERIAL_TOP_REQUEST,
  PACKING_MATERIAL_TOP_SUCCESS,
  PACKING_MATERIAL_TOP_FAIL,
} from "../constants/packingMaterialConstants";
import { logout } from "./userActions";

export const listAllPackingMaterials =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PACKING_MATERIAL_LIST_REQUEST });

      const { data } = await axios.get(`/api/packing-materials/all`);

      dispatch({
        type: PACKING_MATERIAL_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PACKING_MATERIAL_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPackingMaterials =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PACKING_MATERIAL_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/packing-materials?keyword=${keyword}&pageNumber=${pageNumber}`
      );

      dispatch({
        type: PACKING_MATERIAL_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PACKING_MATERIAL_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPackingMaterialDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PACKING_MATERIAL_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/packing-materials/${id}/admin`);

    dispatch({
      type: PACKING_MATERIAL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PACKING_MATERIAL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletePackingMaterial = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PACKING_MATERIAL_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/packing-materials/${id}`, config);

    dispatch({
      type: PACKING_MATERIAL_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PACKING_MATERIAL_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createPackingMaterial = (packingMaterial) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PACKING_MATERIAL_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/packing-materials`, packingMaterial, config);

    dispatch({
      type: PACKING_MATERIAL_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PACKING_MATERIAL_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updatePackingMaterial = (packingMaterial) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PACKING_MATERIAL_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/packing-materials/${packingMaterial._id}`,
      packingMaterial,
      config
    );

    dispatch({
      type: PACKING_MATERIAL_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: PACKING_MATERIAL_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PACKING_MATERIAL_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const createPackingMaterialReview =
  (packingMaterialId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PACKING_MATERIAL_CREATE_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(`/api/packing-materials/${packingMaterialId}/reviews`, review, config);

      dispatch({
        type: PACKING_MATERIAL_CREATE_REVIEW_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: PACKING_MATERIAL_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };

export const listTopPackingMaterials = () => async (dispatch) => {
  try {
    dispatch({ type: PACKING_MATERIAL_TOP_REQUEST });

    const { data } = await axios.get(`/api/packing-materials/top`);

    dispatch({
      type: PACKING_MATERIAL_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PACKING_MATERIAL_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
