import React from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import SearchBox from "./SearchBox";
import { logout } from "../actions/userActions";

const Header = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>Omas</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* <Route render={({ history }) => <SearchBox history={history} />} /> */}
            <Nav className="ml-auto">
              {/* <LinkContainer to='/cart'>
                  <Nav.Link>
                    <i className='fas fa-shopping-cart'></i> Cart
                  </Nav.Link>
                </LinkContainer> */}
              {userInfo && userInfo.isAdmin && (
                <>
                  <Nav.Item>
                    <Nav.Link href="/userlist">Users</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/gallerylist">Galleries</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/sellerlist">Sellers</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/categorylist">Categories</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/productlist">Products</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/orderlist">Orders</Nav.Link>
                  </Nav.Item>
                  <NavDropdown title={"Manage"} id="manage">
                    <LinkContainer to="/shipping-methods">
                      <NavDropdown.Item>Shipping Methods</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/packing-materials">
                      <NavDropdown.Item>Packing Methods</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/settings">
                      <NavDropdown.Item>Settings</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </>
              )}
              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <i className="fas fa-user"></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
