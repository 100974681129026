export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_DETAILS_RESET = "ORDER_DETAILS_RESET";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";
export const ORDER_LIST_RESET = "ORDER_LIST_RESET";

export const ORDER_DELIVER_REQUEST = "ORDER_DELIVER_REQUEST";
export const ORDER_DELIVER_SUCCESS = "ORDER_DELIVER_SUCCESS";
export const ORDER_DELIVER_FAIL = "ORDER_DELIVER_FAIL";
export const ORDER_DELIVER_RESET = "ORDER_DELIVER_RESET";

export const ORDER_INVOICE_REQUEST = "ORDER_INVOICE_REQUEST";
export const ORDER_INVOICE_SUCCESS = "ORDER_INVOICE_SUCCESS";
export const ORDER_INVOICE_FAIL = "ORDER_INVOICE_FAIL";
export const ORDER_INVOICE_RESET = "ORDER_INVOICE_RESET";

export const ORDER_PACK_REQUEST = "ORDER_PACK_REQUEST";
export const ORDER_PACK_SUCCESS = "ORDER_PACK_SUCCESS";
export const ORDER_PACK_FAIL = "ORDER_PACK_FAIL";
export const ORDER_PACK_RESET = "ORDER_PACK_RESET";

export const ORDER_SEND_REQUEST = "ORDER_SEND_REQUEST";
export const ORDER_SEND_SUCCESS = "ORDER_SEND_SUCCESS";
export const ORDER_SEND_FAIL = "ORDER_SEND_FAIL";
export const ORDER_SEND_RESET = "ORDER_SEND_RESET";

export const ORDER_FINISH_REQUEST = "ORDER_FINISH_REQUEST";
export const ORDER_FINISH_SUCCESS = "ORDER_FINISH_SUCCESS";
export const ORDER_FINISH_FAIL = "ORDER_FINISH_FAIL";
export const ORDER_FINISH_RESET = "ORDER_FINISH_RESET";

export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";
export const ORDER_UPDATE_RESET = "ORDER_UPDATE_RESET";

export const ORDER_CANCEL_REQUEST = "ORDER_CANCEL_REQUEST";
export const ORDER_CANCEL_SUCCESS = "ORDER_CANCEL_SUCCESS";
export const ORDER_CANCEL_FAIL = "ORDER_CANCEL_FAIL";
export const ORDER_CANCEL_RESET = "ORDER_CANCEL_RESET";

export const ORDER_ADD_REQUEST = "ORDER_ADD_REQUEST";
export const ORDER_ADD_SUCCESS = "ORDER_ADD_SUCCESS";
export const ORDER_ADD_FAIL = "ORDER_ADD_FAIL";
export const ORDER_ADD_RESET = "ORDER_ADD_RESET";
