import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, ListGroup, Image, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { SITE_URL } from "../config/config";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
  invoiceOrder,
  packOrder,
  sendOrder,
  finishOrder,
  cancelOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
  ORDER_INVOICE_RESET,
  ORDER_PACK_RESET,
  ORDER_SEND_RESET,
  ORDER_FINISH_RESET,
  ORDER_CANCEL_RESET,
} from "../constants/orderConstants";
import { calculatePrice } from "../utils/calculatePrice";
import { customOrderId } from "../utils/customOrderId";
import "./OrderScreen.css";
import { omDateFormat } from "../utils/omDateFormat";

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id;

  const [sdkReady, setSdkReady] = useState(false);
  const [note, setNote] = useState("Payment Completed");

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const orderInvoice = useSelector((state) => state.orderInvoice);
  const { loading: loadingInvoice, success: successInvoice } = orderInvoice;

  const orderPack = useSelector((state) => state.orderPack);
  const { loading: loadingPack, success: successPack } = orderPack;

  const orderSend = useSelector((state) => state.orderSend);
  const { loading: loadingSend, success: successSend } = orderSend;

  const orderFinish = useSelector((state) => state.orderFinish);
  const { loading: loadingFinish, success: successFinish } = orderFinish;

  const orderCancel = useSelector((state) => state.orderCancel);
  const { loading: loadingCancel, success: successCancel } = orderCancel;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    if (
      !order ||
      successPay ||
      successDeliver ||
      successInvoice ||
      successPack ||
      successSend ||
      successFinish ||
      successCancel ||
      order._id !== orderId
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch({ type: ORDER_INVOICE_RESET });
      dispatch({ type: ORDER_PACK_RESET });
      dispatch({ type: ORDER_SEND_RESET });
      dispatch({ type: ORDER_FINISH_RESET });
      dispatch({ type: ORDER_CANCEL_RESET });
      dispatch(getOrderDetails(orderId));
    }
  }, [
    dispatch,
    orderId,
    successPay,
    successDeliver,
    successInvoice,
    successPack,
    successSend,
    successFinish,
    successCancel,
    order,
    history,
    userInfo,
  ]);

  const successPaymentHandler = (paymentResult = {}) => {
    dispatch(
      payOrder(orderId, {
        id: orderId + `-payment`,
        status: true,
        note: note,
        email_address: order.user.email,
      })
    );
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  const invoiceHandler = () => {
    dispatch(invoiceOrder(order));
  };

  const packHandler = () => {
    dispatch(packOrder(order));
  };

  const sendHandler = () => {
    dispatch(sendOrder(order));
  };

  const finishHandler = () => {
    dispatch(finishOrder(order));
  };
  const cancelHandler = () => {
    dispatch(cancelOrder(order));
  };
  const DateConversion = (cDate) => {
    const dat = new Date(cDate);
    const yyyy = String(dat.getFullYear());
    const mm = String(dat.getMonth() + 1);
    const hh = String(dat.getHours());
    const min = String(dat.getMinutes());
    const dd = String(dat.getDate());
    return dd + "." + mm + "." + yyyy;
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <h1>Order #{customOrderId(order.createdAt)}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush" className="order-d">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Name: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>Address:</strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}
                {order.shippingAddress.postalCode},
                {order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant="success">
                  Delivered on {DateConversion(order.deliveredAt)}
                </Message>
              ) : (
                <Message variant="danger">Not Delivered</Message>
              )}
            </ListGroup.Item>

            {order.shippingMethod?.company && (
              <ListGroup.Item>
                <h2>Shipping Method</h2>
                <p>
                  <strong>{order.shippingMethod.company}</strong> (
                  {calculatePrice(order.shippingPrice)})
                </p>
              </ListGroup.Item>
            )}

            {order.packingMaterial?.material && (
              <ListGroup.Item>
                <h2>Packing Material</h2>
                <p>
                  <strong>{order.packingMaterial.material}</strong> (
                  {calculatePrice(order.packingMaterialPrice)})
                </p>
              </ListGroup.Item>
            )}

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant="success">
                  Paid on {DateConversion(order.paidAt)}
                </Message>
              ) : (
                <Message variant="danger">Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        {/*
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        */}
                        <Col>
                          <a
                            target={"_blank"}
                            rel="noopener noreferrer"
                            href={`${
                              SITE_URL + "/products/" + item.product._id
                            }`}
                          >
                            {item.name}
                          </a>
                        </Col>
                        <Col md={4}>
                          {item.qty} x {calculatePrice(item.price)} =
                          {calculatePrice(item.qty * item.price)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Supplier</h2>
              <ul>
                {order.orderItems.map((item, index) => (
                  <li key={index}>
                    <strong>{item.product?.seller?.sAlias}</strong>
                    {/* <ul>
                    <li>Amout to pay to supplier</li>
                    <li>Supplier paid</li>
                  </ul> */}
                  </li>
                ))}
                {/* <li>
                  <strong>Supplier Name 1</strong>
                  <ul>
                    <li>Amout to pay to supplier</li>
                    <li>Supplier paid</li>
                  </ul>
                </li> */}
              </ul>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          {userInfo && userInfo.isAdmin && (
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item className="order-summary">
                  <h2>Order Status</h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Ordered</Col>
                    <Col>{omDateFormat(order.createdAt)}</Col>
                  </Row>
                </ListGroup.Item>
                {order.status ? (
                  <>
                    <ListGroup.Item>
                      <Row>
                        <Col>Invoiced</Col>
                        <Col>
                          {order.isInvoiced ? (
                            omDateFormat(order.invoicedAt)
                          ) : (
                            <Button
                              type="button"
                              className="btn btn-sm"
                              onClick={invoiceHandler}
                            >
                              Mark As Invoiced
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Packed</Col>
                        <Col>
                          {order.isPacked ? (
                            omDateFormat(order.packedAt)
                          ) : (
                            <Button
                              type="button"
                              className="btn btn-sm"
                              onClick={packHandler}
                            >
                              Mark As Packed
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Sent</Col>
                        <Col>
                          {order.isSent ? (
                            omDateFormat(order.sentAt)
                          ) : (
                            <Button
                              type="button"
                              className="btn btn-sm"
                              onClick={sendHandler}
                            >
                              Mark As Sent
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Delivered</Col>
                        <Col>
                          {order.isDelivered ? (
                            omDateFormat(order.deliveredAt)
                          ) : (
                            <Button
                              type="button"
                              className="btn btn-sm"
                              onClick={deliverHandler}
                            >
                              Mark As Delivered
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Paid</Col>
                        <Col>
                          {order.isPaid ? (
                            omDateFormat(order.paidAt)
                          ) : (
                            <Button
                              type="button"
                              className="btn btn-sm"
                              onClick={successPaymentHandler}
                            >
                              Mark As Paid
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Finished</Col>
                        <Col>
                          {order.isFinished ? (
                            omDateFormat(order.finishedAt)
                          ) : (
                            <Button
                              type="button"
                              className="btn btn-sm"
                              onClick={finishHandler}
                            >
                              Mark As Finished
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {!order.isInvoiced ? (
                      <>
                        <ListGroup.Item>
                          <Row>
                            <Col>&nbsp;</Col>
                            <Col>
                              <LinkContainer to={`/order/${order._id}/edit`}>
                                <Button
                                  type="button"
                                  className="btn btn-warning btn-sm"
                                >
                                  Edit Order
                                </Button>
                              </LinkContainer>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>&nbsp;</Col>
                            <Col>
                              <Button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={cancelHandler}
                              >
                                Cancel Order
                              </Button>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <ListGroup.Item>
                    <Row>
                      <Col>Status</Col>
                      <Col className="text-danger">Cancelled</Col>
                    </Row>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card>
          )}
          <br />
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item className="order-summary">
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>ID</Col>
                  <Col>{customOrderId(order.createdAt)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>{calculatePrice(order.itemsPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>{calculatePrice(order.shippingPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Packing</Col>
                  <Col>{calculatePrice(order.packingMaterialPrice)}</Col>
                </Row>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>{calculatePrice(order.taxPrice)}</Col>
                </Row>
              </ListGroup.Item> */}
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>{calculatePrice(order.totalPrice)}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
