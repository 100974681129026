import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listGalleryDetails } from "../actions/galleryActions";
import { GALLERY_CREATE_REVIEW_RESET } from "../constants/galleryConstants";
import Paginate from "../components/Paginate";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { listGalleryImages } from "../actions/galleryImageActions";

const GalleryScreen = ({ history, match }) => {
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const galleryDetails = useSelector((state) => state.galleryDetails);
  const {
    loading,
    error,
    gallery: {
      _id,
      title,
      description,
    },
  } = galleryDetails;

  const galleryImageList = useSelector((state) => state.galleryImageList);
  const {
    loading: loadingGalleryImageList,
    error: errorGalleryImageList,
    galleryImages,
  } = galleryImageList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!_id || _id !== match.params.id) {
      dispatch(listGalleryDetails(match.params.id));
      dispatch(listGalleryImages(match.params.id));
      dispatch({ type: GALLERY_CREATE_REVIEW_RESET });
    }
  }, [dispatch, match]);

  return (
    <Row>
      <Col md={3}>
        <h2>Details</h2>
        {message && <Message variant="danger">{message}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <br />
              {title}
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <br />
              {description}
            </Form.Group>
          </Form>
        )}
      </Col>
      <Col md={9}>
        <h2>Images</h2>
        {loadingGalleryImageList && <Loader />}
        {!loadingGalleryImageList && galleryImages.length > 0 && (
          <Carousel>
            {galleryImages.map((galleryImageData) => (
              <div>
                <img src={`${axios.defaults.baseURL}${galleryImageData.image}`} />
                <p className="legend">{galleryImageData.name}</p>
              </div>
            ))}
          </Carousel>
        )}
      </Col>
    </Row>
  );
};

export default GalleryScreen;
