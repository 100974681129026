import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { createShippingMethod } from "../actions/shippingMethodActions";

const ShippingMethodAddScreen = ({ match, history }) => {
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");
  const [priceDomestic, setPriceDomestic] = useState("");
  const [priceEu, setPriceEU] = useState("");
  const [priceNonEu, setPriceNonEu] = useState("");
  const [priceOverSize, setPriceOverSize] = useState("");
  const [oversizeWeight, setOversizeWeight] = useState("");
  const [oversizeSize, setOversizeSize] = useState("");
  const [taxes, setTaxes] = useState("");
  const [url, setUrl] = useState("");
  const [defaultValue, setDefaultValue] = useState(false);

  const dispatch = useDispatch();

  const shippingMethodCreate = useSelector((state) => state.shippingMethodCreate);
  const {
    loading: loadingAdd,
    error: errorAdd,
    success: successAdd,
  } = shippingMethodCreate;

  useEffect(() => {
    if (successAdd) {
      setTimeout(() => {
        history.push("/shipping-methods");
      }, 3000);
    }
  });

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createShippingMethod({
        company,
        description,
        priceDomestic,
        priceEu,
        priceNonEu,
        priceOverSize,
        oversizeWeight,
        oversizeSize,
        taxes,
        url,
        defaultValue
      })
    );
  };

  return (
    <>
      <Link to="/shipping-methods" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Add Shipping Method</h1>
        {loadingAdd && <Loader />}
        {!loadingAdd && successAdd && (
          <Message variant="success">Success!</Message>
        )}
        {errorAdd && <Message variant="danger">{errorAdd}</Message>}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="company">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="company"
              placeholder="Company"
              onChange={(e) => setCompany(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="description"
              as={"textarea"}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="priceDomestic">
            <Form.Label>Price(Austria)</Form.Label>
            <Form.Control
              type="priceDomestic"
              placeholder="Price(Austria)"
              onChange={(e) => setPriceDomestic(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="priceEu">
            <Form.Label>Price EU</Form.Label>
            <Form.Control
              type="priceEu"
              placeholder="Price EU"
              onChange={(e) => setPriceEU(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="priceNonEu">
            <Form.Label>Price non-EU</Form.Label>
            <Form.Control
              type="priceNonEu"
              placeholder="Price non-EU"
              onChange={(e) => setPriceNonEu(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="priceOverSize">
            <Form.Label>Price Oversize</Form.Label>
            <Form.Control
              type="priceOverSize"
              placeholder="Price Oversize"
              onChange={(e) => setPriceOverSize(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="oversizeWeight">
            <Form.Label>Oversize Weight</Form.Label>
            <Form.Control
              type="oversizeWeight"
              placeholder="Oversize Weight"
              onChange={(e) => setOversizeWeight(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="oversizeSize">
            <Form.Label>Oversize Size</Form.Label>
            <Form.Control
              type="oversizeSize"
              placeholder="Oversize Size"
              onChange={(e) => setOversizeSize(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="taxes">
            <Form.Label>Taxes</Form.Label>
            <Form.Control
              type="taxes"
              placeholder="Taxes"
              onChange={(e) => setTaxes(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="url">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="url"
              placeholder="URL"
              onChange={(e) => setUrl(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="defaultValue">
            <Form.Check
              type="checkbox"
              label="Default"
              onChange={(e) => { setDefaultValue(e.target.checked) }}
            />
          </Form.Group>
          <Button type="submit" variant="primary">
            Add
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default ShippingMethodAddScreen;
