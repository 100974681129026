import axios from "axios";
import {
  SHIPPING_METHOD_LIST_REQUEST,
  SHIPPING_METHOD_LIST_SUCCESS,
  SHIPPING_METHOD_LIST_FAIL,
  SHIPPING_METHOD_DETAILS_REQUEST,
  SHIPPING_METHOD_DETAILS_SUCCESS,
  SHIPPING_METHOD_DETAILS_FAIL,
  SHIPPING_METHOD_DELETE_SUCCESS,
  SHIPPING_METHOD_DELETE_REQUEST,
  SHIPPING_METHOD_DELETE_FAIL,
  SHIPPING_METHOD_CREATE_REQUEST,
  SHIPPING_METHOD_CREATE_SUCCESS,
  SHIPPING_METHOD_CREATE_FAIL,
  SHIPPING_METHOD_UPDATE_REQUEST,
  SHIPPING_METHOD_UPDATE_SUCCESS,
  SHIPPING_METHOD_UPDATE_FAIL,
  SHIPPING_METHOD_CREATE_REVIEW_REQUEST,
  SHIPPING_METHOD_CREATE_REVIEW_SUCCESS,
  SHIPPING_METHOD_CREATE_REVIEW_FAIL,
  SHIPPING_METHOD_TOP_REQUEST,
  SHIPPING_METHOD_TOP_SUCCESS,
  SHIPPING_METHOD_TOP_FAIL,
} from "../constants/shippingMethodConstants";
import { logout } from "./userActions";

export const listAllShippingMethods =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: SHIPPING_METHOD_LIST_REQUEST });

      const { data } = await axios.get(`/api/shipping-methods/all`);

      dispatch({
        type: SHIPPING_METHOD_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SHIPPING_METHOD_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listShippingMethods =
  (keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: SHIPPING_METHOD_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/shipping-methods?keyword=${keyword}&pageNumber=${pageNumber}`
      );

      dispatch({
        type: SHIPPING_METHOD_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SHIPPING_METHOD_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listShippingMethodDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SHIPPING_METHOD_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/shipping-methods/${id}/admin`);

    dispatch({
      type: SHIPPING_METHOD_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SHIPPING_METHOD_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteShippingMethod = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SHIPPING_METHOD_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/shipping-methods/${id}`, config);

    dispatch({
      type: SHIPPING_METHOD_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SHIPPING_METHOD_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createShippingMethod = (shippingMethod) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SHIPPING_METHOD_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/shipping-methods`, shippingMethod, config);

    dispatch({
      type: SHIPPING_METHOD_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SHIPPING_METHOD_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateShippingMethod = (shippingMethod) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SHIPPING_METHOD_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/shipping-methods/${shippingMethod._id}`,
      shippingMethod,
      config
    );

    dispatch({
      type: SHIPPING_METHOD_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: SHIPPING_METHOD_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SHIPPING_METHOD_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const createShippingMethodReview =
  (shippingMethodId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SHIPPING_METHOD_CREATE_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(`/api/shipping-methods/${shippingMethodId}/reviews`, review, config);

      dispatch({
        type: SHIPPING_METHOD_CREATE_REVIEW_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: SHIPPING_METHOD_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };

export const listTopShippingMethods = () => async (dispatch) => {
  try {
    dispatch({ type: SHIPPING_METHOD_TOP_REQUEST });

    const { data } = await axios.get(`/api/shipping-methods/top`);

    dispatch({
      type: SHIPPING_METHOD_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SHIPPING_METHOD_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
