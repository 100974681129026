import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listOrders } from "../actions/orderActions";
import { calculatePrice } from "../utils/calculatePrice";
import { customOrderId } from "../utils/customOrderId";
import {
  payOrder,
  deliverOrder,
  invoiceOrder,
  packOrder,
  sendOrder,
  finishOrder,
  cancelOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
  ORDER_INVOICE_RESET,
  ORDER_PACK_RESET,
  ORDER_SEND_RESET,
  ORDER_FINISH_RESET,
  ORDER_CANCEL_RESET,
} from "../constants/orderConstants";
import { omDateFormat } from "../utils/omDateFormat";
const OrderListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [note, setNote] = useState("Payment Completed");

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const orderInvoice = useSelector((state) => state.orderInvoice);
  const { loading: loadingInvoice, success: successInvoice } = orderInvoice;

  const orderPack = useSelector((state) => state.orderPack);
  const { loading: loadingPack, success: successPack } = orderPack;

  const orderSend = useSelector((state) => state.orderSend);
  const { loading: loadingSend, success: successSend } = orderSend;

  const orderFinish = useSelector((state) => state.orderFinish);
  const { loading: loadingFinish, success: successFinish } = orderFinish;

  const orderCancel = useSelector((state) => state.orderCancel);
  const { loading: loadingCancel, success: successCancel } = orderCancel;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders());
    } else {
      history.push("/login");
    }
    if (
      successPay ||
      successDeliver ||
      successInvoice ||
      successPack ||
      successSend ||
      successFinish ||
      successCancel
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch({ type: ORDER_INVOICE_RESET });
      dispatch({ type: ORDER_PACK_RESET });
      dispatch({ type: ORDER_SEND_RESET });
      dispatch({ type: ORDER_FINISH_RESET });
      dispatch({ type: ORDER_CANCEL_RESET });
    }
  }, [
    dispatch,
    history,
    userInfo,
    successPay,
    successDeliver,
    successInvoice,
    successPack,
    successSend,
    successFinish,
    successCancel,
  ]);

  const successPaymentHandler = (paymentResult = {}, orderId, order) => {
    // console.log(order);
    dispatch(
      payOrder(orderId, {
        id: orderId + `-payment`,
        status: true,
        note: note,
        email_address: order.user.email,
      })
    );
  };

  const deliverHandler = (order) => {
    dispatch(deliverOrder(order));
  };

  const invoiceHandler = (order) => {
    dispatch(invoiceOrder(order));
  };

  const packHandler = (order) => {
    dispatch(packOrder(order));
  };

  const sendHandler = (order) => {
    dispatch(sendOrder(order));
  };

  const finishHandler = (order) => {
    dispatch(finishOrder(order));
  };

  const cancelHandler = (order) => {
    dispatch(cancelOrder(order));
  };
  return (
    <>
      <h1>Orders</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>USER</th>
              <th>TOTAL</th>
              <th>ORDERED</th>
              <th>INVOICED</th>
              <th>PACKED</th>
              <th>SENT</th>
              <th>DELIVERED</th>
              <th>PAID</th>
              <th>FINISHED</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr
                key={order._id}
                className={!order.status ? "bg-danger text-white" : ""}
              >
                <td>{customOrderId(order.createdAt)}</td>
                <td>{order.user && order.user.name}</td>
                <td>{calculatePrice(order.totalPrice)}</td>
                <td>{omDateFormat(order.createdAt)}</td>
                <td>
                  {order.isInvoiced ? (
                    <>
                    {omDateFormat(order.invoicedAt)}
                    </>
                  ) : order.status ? (
                    <Button
                      type="button"
                      className="btn btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        invoiceHandler(order);
                      }}
                    >
                      Invoiced
                    </Button>
                  ) : (
                    "Cancelled"
                  )}
                </td>
                <td>
                  {order.isPacked ? (
                    omDateFormat(order.packedAt)
                  ) : order.status ? (
                    <Button
                      type="button"
                      className="btn btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        packHandler(order);
                      }}
                    >
                      Packed
                    </Button>
                  ) : (
                    "Cancelled"
                  )}
                </td>
                <td>
                  {order.isSent ? (
                    omDateFormat(order.sentAt)
                  ) : order.status ? (
                    <Button
                      type="button"
                      className="btn btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        sendHandler(order);
                      }}
                    >
                      Sent
                    </Button>
                  ) : (
                    "Cancelled"
                  )}
                </td>
                <td>
                  {order.isDelivered ? (
                    omDateFormat(order.deliveredAt)
                  ) : order.status ? (
                    <Button
                      type="button"
                      className="btn btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        deliverHandler(order);
                      }}
                    >
                      Delivered
                    </Button>
                  ) : (
                    "Cancelled"
                  )}
                </td>
                <td>
                  {order.isPaid ? (
                    omDateFormat(order.paidAt)
                  ) : order.status ? (
                    <Button
                      type="button"
                      className="btn btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        successPaymentHandler(
                          order.paymentResult,
                          order._id,
                          order
                        );
                      }}
                    >
                      Paid
                    </Button>
                  ) : (
                    "Cancelled"
                  )}
                </td>
                <td>
                  {order.isFinished ? (
                    omDateFormat(order.finishedAt)
                  ) : order.status ? (
                    <Button
                      type="button"
                      className="btn btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        finishHandler(order);
                      }}
                    >
                      Finished
                    </Button>
                  ) : (
                    "Cancelled"
                  )}
                </td>
                <td>
                  <LinkContainer to={`/order/${order._id}`}>
                    <Button variant="success" className="btn-sm">
                      <i className="fas fa-eye"></i>
                    </Button>
                  </LinkContainer>
                  {!order.isInvoiced && order.status ? (
                    <>
                      <LinkContainer to={`/order/${order._id}/edit`}>
                        <Button variant="warning" className="btn-sm">
                          <i className="fas fa-edit"></i>
                        </Button>
                      </LinkContainer>
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={(e) => {
                          e.preventDefault();
                          cancelHandler(order);
                        }}
                      >
                        <i className="fas fa-ban"></i>
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default OrderListScreen;
