import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { createProduct } from "../actions/productActions";
import { listAllSellers } from "../actions/sellerActions";
import { listAllCategories } from "../actions/categoryActions";

const ProductAddScreen = ({ match, history }) => {
  const [seller, setSeller] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [maxOrderQuantity, setMaxOrderQuantity] = useState("");
  const [minQuantity, setMinQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [countInStock, setCountInStock] = useState("");
  const [featured, setFeatured] = useState(false);
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const sellerList = useSelector((state) => state.sellerList);
  const {
    loading: loadingSeller,
    error: errorSeller,
    sellers,
    page,
    pages,
  } = sellerList;

  const categoryList = useSelector((state) => state.categoryList);
  const {
    loading: loadingCategory,
    error: errorCategory,
    categories,
    page: pageCategory,
    pages: pagesCategory,
  } = categoryList;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingAdd,
    error: errorAdd,
    success: successAdd,
  } = productCreate;

  useEffect(() => {
    dispatch(listAllSellers());
    dispatch(listAllCategories());
  }, []);

  let sellerOptions = [];
  if (!loadingSeller && sellers.length > 0) {
    sellers.map((seller, index) =>
      sellerOptions.push(
        <option key={`seller-${index}`} value={seller._id}>
          {seller.sAlias}
        </option>
      )
    );
  }

  let categoryOptions = [];
  if (!loadingCategory && categories.length > 0) {
    categories.map((category, index) =>
      categoryOptions.push(
        <option key={`category-${index}`} value={category._id}>
          {category.name}
        </option>
      )
    );
  }

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(seller);
    dispatch(
      createProduct({
        seller,
        name,
        category,
        description,
        price,
        discount,
        tax,
        maxOrderQuantity,
        minQuantity,
        unit,
        countInStock,
        featured,
        image,
      })
    );
  };

  return (
    <>
      <Link to="/productlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Add Product</h1>
        {loadingAdd && <Loader />}
        {!loadingAdd && successAdd && (
          <Message variant="success">Product Added</Message>
        )}
        {errorAdd && <Message variant="danger">{errorAdd}</Message>}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="productName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="productName"
              placeholder="Product Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="lName">
            <Form.Label>Seller</Form.Label>
            <Form.Control
              type="seller"
              as="select"
              placeholder="Seller"
              onChange={(e) => setSeller(e.target.value)}
            >
              {loadingSeller ? (
                <option>Loading Sellers...</option>
              ) : (
                <>
                  <option>Select</option>
                  {sellerOptions}
                </>
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="category"
              as="select"
              placeholder="Category"
              onChange={(e) => setCategory(e.target.value)}
            >
              {loadingCategory ? (
                <option>Loading Categories...</option>
              ) : (
                <>
                  <option>Select</option>
                  {categoryOptions}
                </>
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="description"
              as="textarea"
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="price"
              placeholder="Price"
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="discount">
            <Form.Label>Discount</Form.Label>
            <Form.Control
              type="discount"
              placeholder="discount"
              onChange={(e) => setDiscount(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="tax">
            <Form.Label>Tax</Form.Label>
            <Form.Control
              type="tax"
              placeholder="Tax"
              onChange={(e) => setTax(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="maxOrderQuantity">
            <Form.Label>Maximum Order Quantity</Form.Label>
            <Form.Control
              type="maxOrderQuantity"
              placeholder="Maximum Order Quantity"
              onChange={(e) => setMaxOrderQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="minQuantity">
            <Form.Label>Minimum Stock</Form.Label>
            <Form.Control
              type="minQuantity"
              placeholder="Minimum Stock"
              onChange={(e) => setMinQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="unit">
            <Form.Label>Unit</Form.Label>
            <Form.Control
              type="unit"
              as="select"
              placeholder="Unit"
              onChange={(e) => setUnit(e.target.value)}
            >
              <option>Select</option>
              <option value={"Liter"}>Liter</option>
              <option value={"Units"}>Units</option>
              <option value={"Kilo"}>Kilo</option>
              <option value={"Grams"}>Grams</option>
              <option value={"Packung"}>Packung</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="stock">
            <Form.Label>Stock</Form.Label>
            <Form.Control
              type="stock"
              placeholder="Stock"
              onChange={(e) => setCountInStock(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="image">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="text"
              disabled={true}
              placeholder="Selected image"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
            <Form.File
              id="image-file"
              label="Choose File"
              custom
              onChange={uploadFileHandler}
            />
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="featured">
            <Form.Check
              type="checkbox"
              label="Featured Product"
              onChange={(e) => {
                console.log(e.target.checked);
                return setFeatured(e.target.checked);
              }}
            />
          </Form.Group>
          <Button type="submit" variant="primary">
            Create
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default ProductAddScreen;
