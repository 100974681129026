import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { listProductDetails, updateProduct } from "../actions/productActions";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";
import { listAllSellers } from "../actions/sellerActions";
import { listAllCategories } from "../actions/categoryActions";
import { API_URL } from "../config/config";

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id;

  const [seller, setSeller] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [maxOrderQuantity, setMaxOrderQuantity] = useState("");
  const [minQuantity, setMinQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [countInStock, setCountInStock] = useState("");
  const [featured, setFeatured] = useState(false);
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const sellerList = useSelector((state) => state.sellerList);
  const {
    loading: loadingSeller,
    error: errorSeller,
    sellers,
    page,
    pages,
  } = sellerList;

  const categoryList = useSelector((state) => state.categoryList);
  const {
    loading: loadingCategory,
    error: errorCategory,
    categories,
    page: pageCategory,
    pages: pagesCategory,
  } = categoryList;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    dispatch(listAllSellers());
    dispatch(listAllCategories());
  }, []);

  let sellerOptions = [];
  if (!loadingSeller && sellers.length > 0) {
    sellers.map((seller, index) => {
      return sellerOptions.push(
        <option key={`seller-${index}`} value={seller._id}>
          {seller.sAlias}
        </option>
      );
    });
  }

  let categoryOptions = [];
  if (!loadingCategory && categories.length > 0) {
    categories.map((category, index) =>
      categoryOptions.push(
        <option key={`category-${index}`} value={category._id}>
          {category.name}
        </option>
      )
    );
  }

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      history.push("/productlist");
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId));
      } else {
        setSeller(product.seller);
        setName(product.name);
        setCategory(product.category);
        setDescription(product.description);
        setPrice(product.price);
        setDiscount(product.discount);
        setTax(product.tax);
        setMaxOrderQuantity(product.maxOrderQuantity);
        setMinQuantity(product.minQuantity);
        setUnit(product.unit);
        setCountInStock(product.countInStock);
        setFeatured(product.featured);
        setImage(product.image);
      }
    }
  }, [dispatch, history, productId, product, successUpdate]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        seller,
        name,
        category,
        description,
        price,
        discount,
        tax,
        maxOrderQuantity,
        minQuantity,
        unit,
        countInStock,
        featured,
        image,
      })
    );
  };

  return (
    <>
      <Link to="/productlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="productName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="productName"
                value={name}
                placeholder="Product Name"
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="seller">
              <Form.Label>Seller</Form.Label>
              {loadingSeller ? (
                <span>Loading sellers...</span>
              ) : (
                <Form.Control
                  type="seller"
                  as="select"
                  value={seller._id}
                  placeholder="Seller"
                  onChange={(e) => setSeller(e.target.value)}
                >
                  <>
                    <option>Select</option>
                    {sellerOptions}
                  </>
                </Form.Control>
              )}
            </Form.Group>
            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              {loadingCategory ? (
                <span>Loading categories...</span>
              ) : (
                <Form.Control
                  type="category"
                  as="select"
                  placeholder="Category"
                  value={category?._id}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <>
                    <option>Select</option>
                    {categoryOptions}
                  </>
                </Form.Control>
              )}
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="description"
                as="textarea"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="price"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="discount">
              <Form.Label>Discount</Form.Label>
              <Form.Control
                type="discount"
                placeholder="discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="tax">
              <Form.Label>Tax</Form.Label>
              <Form.Control
                type="tax"
                placeholder="Tax"
                value={tax}
                onChange={(e) => setTax(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="maxOrderQuantity">
              <Form.Label>Maximum Order Quantity</Form.Label>
              <Form.Control
                type="maxOrderQuantity"
                placeholder="Maximum Order Quantity"
                value={maxOrderQuantity}
                onChange={(e) => setMaxOrderQuantity(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="minQuantity">
              <Form.Label>Minimum Stock</Form.Label>
              <Form.Control
                type="minQuantity"
                placeholder="Minimum Stock"
                value={minQuantity}
                onChange={(e) => setMinQuantity(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="unit">
              <Form.Label>Unit</Form.Label>
              <Form.Control
                type="unit"
                as="select"
                placeholder="Unit"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              >
                <option>Liter</option>
                <option>Units</option>
                <option>Kilo</option>
                <option>Grams</option>
                <option>Packung</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="stock">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                type="stock"
                placeholder="Stock"
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Row>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    disabled={true}
                    placeholder="Selected image"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  />
                  <Form.File
                    id="image-file"
                    label="Choose File"
                    custom
                    onChange={uploadFileHandler}
                  />
                </Col>
                <Col>
                  <Image src={`${API_URL + image}`} width={100} />
                </Col>
              </Row>
              {uploading && <Loader />}
            </Form.Group>
            <Form.Group controlId="featured">
              <Form.Check
                type="checkbox"
                label="Featured Product"
                checked={featured}
                onChange={(e) => {
                  console.log(e.target.checked);
                  return setFeatured(e.target.checked);
                }}
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
