import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listSettings, updateSetting } from "../actions/settingActions";
const SettingListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const settingList = useSelector((state) => state.settingList);
  const { loading, error, settings } = settingList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push("/login");
    }
    dispatch(listSettings());
  }, [dispatch, history, userInfo]);

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>Settings</h1>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>VALUE</th>
              </tr>
            </thead>
            <tbody>
              {settings.map((setting) => (
                <tr key={setting._id}>
                  <td>{setting.key}</td>
                  <td>{setting.value}</td>
                  <td>
                    <LinkContainer to={`/settings/${setting._id}/edit`}>
                      <Button variant="warning" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default SettingListScreen;
