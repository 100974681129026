import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_FAIL,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_RESET,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_RESET,
  ORDER_CREATE_RESET,
  ORDER_INVOICE_REQUEST,
  ORDER_INVOICE_SUCCESS,
  ORDER_INVOICE_FAIL,
  ORDER_INVOICE_RESET,
  ORDER_PACK_REQUEST,
  ORDER_PACK_SUCCESS,
  ORDER_PACK_FAIL,
  ORDER_PACK_RESET,
  ORDER_SEND_REQUEST,
  ORDER_SEND_SUCCESS,
  ORDER_SEND_FAIL,
  ORDER_SEND_RESET,
  ORDER_FINISH_REQUEST,
  ORDER_FINISH_SUCCESS,
  ORDER_FINISH_FAIL,
  ORDER_FINISH_RESET,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_RESET,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CANCEL_RESET,
  ORDER_DETAILS_RESET,
  ORDER_ADD_REQUEST,
  ORDER_ADD_SUCCESS,
  ORDER_ADD_FAIL,
  ORDER_ADD_RESET,
  ORDER_LIST_RESET,
} from "../constants/orderConstants";

export const orderAddProductReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ADD_REQUEST:
      return {
        loading: true,
      };
    case ORDER_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDetailsReducer = (
  state = { loading: true, orderItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const orderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return {
        loading: true,
      };
    case ORDER_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_PAY_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELIVER_REQUEST:
      return {
        loading: true,
      };
    case ORDER_DELIVER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_DELIVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_DELIVER_RESET:
      return {};
    default:
      return state;
  }
};

export const orderInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_INVOICE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_INVOICE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_INVOICE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_INVOICE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderPackReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PACK_REQUEST:
      return {
        loading: true,
      };
    case ORDER_PACK_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_PACK_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_PACK_RESET:
      return {};
    default:
      return state;
  }
};

export const orderSendReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_SEND_REQUEST:
      return {
        loading: true,
      };
    case ORDER_SEND_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_SEND_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_SEND_RESET:
      return {};
    default:
      return state;
  }
};

export const orderFinishReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_FINISH_REQUEST:
      return {
        loading: true,
      };
    case ORDER_FINISH_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_FINISH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_FINISH_RESET:
      return {};
    default:
      return state;
  }
};

export const orderCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CANCEL_REQUEST:
      return {
        loading: true,
      };
    case ORDER_CANCEL_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_CANCEL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_CANCEL_RESET:
      return {};
    default:
      return state;
  }
};

export const orderListMyReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_MY_REQUEST:
      return {
        loading: true,
      };
    case ORDER_LIST_MY_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_LIST_MY_RESET:
      return { orders: [] };
    default:
      return state;
  }
};

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_LIST_RESET:
      return {};
    default:
      return state;
  }
};
