import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import {
  listSettings,
  updateSetting,
  detailSetting,
} from "../actions/settingActions";
import { SETTING_UPDATE_RESET } from "../constants/settingConstants";

const SettingEditScreen = ({ match, history }) => {
  const settingId = match.params.id;

  const [settingValue, setSettingValue] = useState("");
  const [settingKey, setSettingKey] = useState("");
  const dispatch = useDispatch();

  const settingDetails = useSelector((state) => state.settingDetails);
  const { loading, error, setting } = settingDetails;
  const settingUpdate = useSelector((state) => state.settingUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = settingUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SETTING_UPDATE_RESET });
      history.push("/settings");
    } else {
      console.log(setting);
      if (!setting.key || setting._id !== settingId) {
        dispatch(detailSetting(settingId));
      } else {
        setSettingValue(setting.value);
        setSettingKey(setting.key);
      }
    }
  }, [dispatch, history, setting, settingId, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(settingKey, settingValue);
    dispatch(
      updateSetting({
        _id: settingId,
        key: settingKey,
        value: settingValue,
      })
    );
  };

  return (
    <>
      <Link to="/settings" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Setting</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="settingValue">
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="settingValue"
                value={settingValue}
                onChange={(e) => setSettingValue(e.target.value)}
              />
            </Form.Group>

            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default SettingEditScreen;
