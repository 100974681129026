export const SETTING_LIST_REQUEST = "SETTING_LIST_REQUEST";
export const SETTING_LIST_SUCCESS = "SETTING_LIST_SUCCESS";
export const SETTING_LIST_FAIL = "SETTING_LIST_FAIL";

export const SETTING_UPDATE_REQUEST = "SETTING_UPDATE_REQUEST";
export const SETTING_UPDATE_SUCCESS = "SETTING_UPDATE_SUCCESS";
export const SETTING_UPDATE_FAIL = "SETTING_UPDATE_FAIL";
export const SETTING_UPDATE_RESET = "SETTING_UPDATE_RESET";

export const SETTING_DETAILS_REQUEST = "SETTING_DETAILS_REQUEST";
export const SETTING_DETAILS_SUCCESS = "SETTING_DETAILS_SUCCESS";
export const SETTING_DETAILS_FAIL = "SETTING_DETAILS_FAIL";
export const SETTING_DETAILS_RESET = "SETTING_DETAILS_RESET";
