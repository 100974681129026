import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listProducts, listProductsBySeller } from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import { listSellerDetails } from "../actions/sellerActions";
import { SELLER_CREATE_REVIEW_RESET } from "../constants/sellerConstants";
import Paginate from "../components/Paginate";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { listSellerImages } from "../actions/sellerImageActions";

const SellerScreen = ({ history, match }) => {
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const sellerDetails = useSelector((state) => state.sellerDetails);
  const {
    loading,
    error,
    seller: {
      _id,
      fName,
      lName,
      address,
      city,
      zip,
      email,
      phone,
      sAlias,
      taxes,
      taxNumber,
      notification,
    },
  } = sellerDetails;

  const sellerImageList = useSelector((state) => state.sellerImageList);
  const {
    loading: loadingSellerImageList,
    error: errorSellerImageList,
    sellerImages,
  } = sellerImageList;

  const productList = useSelector((state) => state.productList);
  const {
    loading: loadingProduct,
    error: loadingError,
    products,
    page,
    pages,
  } = productList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Get Products
  // const orderListMy = useSelector((state) => state.orderListMy);
  // const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  useEffect(() => {
    if (!_id || _id !== match.params.id) {
      dispatch(listSellerDetails(match.params.id));
      dispatch(listSellerImages(match.params.id));
      dispatch(listProductsBySeller(match.params.id, ""));
      dispatch({ type: SELLER_CREATE_REVIEW_RESET });
    }
  }, [dispatch, match]);

  return (
    <Row>
      <Col md={3}>
        <h2>Seller Profile</h2>
        {message && <Message variant="danger">{message}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <br />
              {fName} {lName}
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <br />
              {email}
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <br />
              {phone}
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <br />
              {address}
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <br />
              {city}
            </Form.Group>

            <Form.Group controlId="zip">
              <Form.Label>ZIP</Form.Label>
              <br />
              {zip}
            </Form.Group>

            <Form.Group controlId="alias">
              <Form.Label>Alias</Form.Label>
              <br />
              {sAlias}
            </Form.Group>

            <Form.Group controlId="taxes">
              <Form.Label>Taxes</Form.Label>
              <br />
              {taxes}
            </Form.Group>

            <Form.Group controlId="taxNumber">
              <Form.Label>Tax Number</Form.Label>
              <br />
              {taxNumber}
            </Form.Group>

            <Form.Group controlId="notification">
              <Form.Label>Notification Method</Form.Label>
              <br />
              {notification === "EMAIL"
                ? "Email"
                : notification === "PHONE"
                ? "Phone"
                : "NA"}
            </Form.Group>
          </Form>
        )}
      </Col>
      <Col md={9}>
        <h2>Seller Images</h2>
        {loadingSellerImageList && <Loader />}
        {!loadingSellerImageList && sellerImages.length > 0 && (
          <Carousel>
            {sellerImages.map((sellerImageData) => (
              <div>
                <img src={`${axios.defaults.baseURL}${sellerImageData.image}`} />
                <p className="legend">{sellerImageData.name}</p>
              </div>
            ))}
          </Carousel>
        )}
        <h2>Seller Products</h2>
        {loadingProduct ? (
          <Loader />
        ) : loadingError ? (
          <Message variant="danger">{loadingError}</Message>
        ) : (
          <>
            <Table striped bordered hover responsive className="table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NAME</th>
                  <th>PRICE</th>
                  <th>CATEGORY</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product._id}>
                    <td>{product.puid}</td>
                    <td>{product.name}</td>
                    <td>€{product.price}</td>
                    <td>{product.category?.name}</td>
                    <td>
                      <LinkContainer to={`/product/${product._id}`}>
                        <Button variant="success" className="btn-sm">
                          <i className="fas fa-eye"></i>
                        </Button>
                      </LinkContainer>
                      <LinkContainer to={`/product/${product._id}/edit`}>
                        <Button variant="warning" className="btn-sm">
                          <i className="fas fa-edit"></i>
                        </Button>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Paginate pages={pages} page={page} isAdmin={true} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default SellerScreen;
