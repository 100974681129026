import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { createSeller } from "../actions/sellerActions";

const SellerAddScreen = ({ match, history }) => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sAlias, setSalias] = useState("");
  const [taxes, setTaxes] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [notification, setNotification] = useState("");

  const dispatch = useDispatch();

  const sellerCreate = useSelector((state) => state.sellerCreate);
  const {
    loading: loadingAdd,
    error: errorAdd,
    success: successAdd,
  } = sellerCreate;

  /*  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };*/

  useEffect(() => {
    if (successAdd) {
      setTimeout(() => {
        history.push("/sellerlist");
      }, 3000);
    }
  });

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createSeller({
        fName,
        lName,
        address,
        city,
        zip,
        email,
        phone,
        sAlias,
        taxes,
        taxNumber,
        notification,
      })
    );
  };

  return (
    <>
      <Link to="/sellerlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Add Seller</h1>
        {loadingAdd && <Loader />}
        {!loadingAdd && successAdd && (
          <Message variant="success">Success!</Message>
        )}
        {errorAdd && <Message variant="danger">{errorAdd}</Message>}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="fName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="fname"
              placeholder="Enter First Name"
              onChange={(e) => setFname(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="lName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="lname"
              placeholder="Enter First Name"
              onChange={(e) => setLname(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              as="textarea"
              type="address"
              placeholder="Address"
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="city"
              placeholder="City"
              onChange={(e) => setCity(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="zip">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="zip"
              placeholder="Zip"
              onChange={(e) => setZip(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="emali">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="phone"
              placeholder="Phone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="alias">
            <Form.Label>Alias</Form.Label>
            <Form.Control
              type="sAlias"
              placeholder="Alias"
              onChange={(e) => setSalias(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="alias">
            <Form.Label>Taxes</Form.Label>
            <Form.Control
              type="taxes"
              placeholder="Taxes"
              onChange={(e) => setTaxes(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="taxNumber">
            <Form.Label>Tax Number</Form.Label>
            <Form.Control
              type="taxNumber"
              placeholder="Tax Number"
              onChange={(e) => setTaxNumber(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="notification">
            <Form.Label>Notification Method</Form.Label>
            <Form.Control
              as="select"
              type="notification"
              onChange={(e) => setNotification(e.target.value)}
            >
              <option>Select</option>
              <option value="EMAIL">Email</option>
              <option value="PHONE">Phone</option>
            </Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary">
            Create
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default SellerAddScreen;
