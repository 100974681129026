import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import {
  listShippingMethodDetails,
  updateShippingMethod,
} from "../actions/shippingMethodActions";
import { SHIPPING_METHOD_UPDATE_RESET } from "../constants/shippingMethodConstants";

const ShippingMethodEditScreen = ({ match, history }) => {
  const shippingMethodId = match.params.id;

  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");
  const [priceDomestic, setPriceDomestic] = useState("");
  const [priceEu, setPriceEU] = useState("");
  const [priceNonEu, setPriceNonEu] = useState("");
  const [priceOverSize, setPriceOverSize] = useState("");
  const [oversizeWeight, setOversizeWeight] = useState("");
  const [oversizeSize, setOversizeSize] = useState("");
  const [taxes, setTaxes] = useState("");
  const [url, setUrl] = useState("");
  const [defaultValue, setDefaultValue] = useState(false);

  const dispatch = useDispatch();

  const shippingMethodDetails = useSelector((state) => state.shippingMethodDetails);
  const { loading, error, shippingMethod } = shippingMethodDetails;

  const shippingMethodUpdate = useSelector((state) => state.shippingMethodUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = shippingMethodUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SHIPPING_METHOD_UPDATE_RESET });
      history.push("/shipping-methods");
    } else {
      if (!shippingMethod.company || shippingMethod._id !== shippingMethodId) {
        dispatch(listShippingMethodDetails(shippingMethodId));
      } else {
        setCompany(shippingMethod.company)
        setDescription(shippingMethod.description)
        setPriceDomestic(shippingMethod.priceDomestic)
        setPriceEU(shippingMethod.priceEu)
        setPriceNonEu(shippingMethod.priceNonEu)
        setPriceOverSize(shippingMethod.priceOverSize)
        setOversizeWeight(shippingMethod.oversizeWeight)
        setOversizeSize(shippingMethod.oversizeSize)
        setTaxes(shippingMethod.taxes)
        setUrl(shippingMethod.url)
        setDefaultValue(shippingMethod.defaultValue)
      }
    }
  }, [dispatch, history, shippingMethodId, shippingMethod, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateShippingMethod({
        _id: shippingMethodId,
        company,
        description,
        priceDomestic,
        priceEu,
        priceNonEu,
        priceOverSize,
        oversizeWeight,
        oversizeSize,
        taxes,
        url,
        defaultValue
      })
    );
  };

  return (
    <>
      <Link to="/shipping-methods" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Shipping Method</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="company">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="company"
              placeholder="Company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="description"
              as={"textarea"}
              value={description}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="priceDomestic">
            <Form.Label>Price(Austria)</Form.Label>
            <Form.Control
              type="priceDomestic"
              value={priceDomestic}
              placeholder="Price(Austria)"
              onChange={(e) => setPriceDomestic(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="priceEu">
            <Form.Label>Price EU</Form.Label>
            <Form.Control
              type="priceEu"
              value={priceEu}
              placeholder="Price EU"
              onChange={(e) => setPriceEU(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="priceNonEu">
            <Form.Label>Price non-EU</Form.Label>
            <Form.Control
              type="priceNonEu"
              value={priceNonEu}
              placeholder="Price non-EU"
              onChange={(e) => setPriceNonEu(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="priceOverSize">
            <Form.Label>Price Oversize</Form.Label>
            <Form.Control
              type="priceOverSize"
              value={priceOverSize}
              placeholder="Price Oversize"
              onChange={(e) => setPriceOverSize(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="oversizeWeight">
            <Form.Label>Oversize Weight</Form.Label>
            <Form.Control
              type="oversizeWeight"
              value={oversizeWeight}
              placeholder="Oversize Weight"
              onChange={(e) => setOversizeWeight(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="oversizeSize">
            <Form.Label>Oversize Size</Form.Label>
            <Form.Control
              type="oversizeSize"
              value={oversizeSize}
              placeholder="Oversize Size"
              onChange={(e) => setOversizeSize(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="taxes">
            <Form.Label>Taxes</Form.Label>
            <Form.Control
              type="taxes"
              value={taxes}
              placeholder="Taxes"
              onChange={(e) => setTaxes(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="url">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="url"
              value={url}
              placeholder="URL"
              onChange={(e) => setUrl(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="defaultValue">
            <Form.Check
              type="checkbox"
              label="Default"
              checked={defaultValue}
              onChange={(e) => { setDefaultValue(e.target.checked) }}
            />
          </Form.Group>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ShippingMethodEditScreen;
