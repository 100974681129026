import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import { calculatePrice } from "../utils/calculatePrice";
import {
  getOrderDetails,
  updateOrder,
  listOrders,
  addProductOrder,
} from "../actions/orderActions";
import { listProducts } from "../actions/productActions";
import { ORDER_DETAILS_RESET } from "../constants/orderConstants";
import { ORDER_ADD_RESET } from "../constants/orderConstants";
import { PRODUCT_LIST_RESET } from "../constants/productConstants";
import { customOrderId } from "../utils/customOrderId";
import { API_URL } from "../config/config";
const OrderEditScreen = ({ match, history }) => {
  const orderId = match.params.id;
  const dispatch = useDispatch();
  const orderDetails = useSelector((state) => state.orderDetails);
  const productList = useSelector((state) => state.productList);
  const { products } = productList;
  const orderAddProduct = useSelector((state) => state.orderAddProduct);
  const {
    loading: loadingAddProduct,
    error: errorAddProduct,
    success: successAddProduct,
  } = orderAddProduct;
  const { order, loading, error } = orderDetails;
  const orderUpdateDetails = useSelector((state) => state.orderUpdate);

  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = orderUpdateDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [orderItems, setOrderItems] = useState([]);
  const [search, setSearch] = useState([]);

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
    if (successAddProduct) {
      dispatch(getOrderDetails(orderId));
      dispatch({ type: ORDER_ADD_RESET });
      dispatch({ type: PRODUCT_LIST_RESET });
    }
    if (!order || order._id !== orderId) {
      dispatch(getOrderDetails(orderId));
    } else {
      setOrderItems(order.orderItems);
    }

    return () => {};
  }, [dispatch, orderId, order, successAddProduct]);
  const addProductHandler = (orderId, productId) => {
    dispatch(addProductOrder(orderId, productId));
  };
  const searchHandler = (event) => {
    const { value } = event.target;
    setSearch(value);
    dispatch(listProducts(value));
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  // console.log(products);
  const optimisedVerion = useCallback(debounce(searchHandler), []);

  const handleChange = (id, product, newQty) => {
    const newList = orderItems.map((item) => {
      if (item._id === id) {
        let newPrice = parseFloat(product?.price);
        if (product?.discount > 0) {
          newPrice = newPrice - newPrice * (product?.discount / 100);
        }
        // if (product?.tax > 0) {
        //   newPrice = newPrice + newPrice * (product?.tax / 100);
        // }
        newPrice = newPrice.toFixed(2);
        const updatedItem = {
          ...item,
          qty: newQty,
          price: newPrice,
        };

        return updatedItem;
      }

      return item;
    });

    setOrderItems(newList);
  };
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  let orderItemsPrice = addDecimals(
    orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  let orderTotalPrice = (
    Number(orderItemsPrice) +
    Number(order?.shippingPrice) +
    Number(order?.packingMaterialPrice)
  ).toFixed(2);

  let billingAddressObj = {};
  if (order && !order?.billingAddress) {
    const { address, addressName, city, postalCode, country } =
      order.shippingAddress;
    billingAddressObj = {
      address,
      addressName,
      city,
      postalCode,
      country,
    };
  } else {
    billingAddressObj = order?.billingAddress;
  }

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateOrder(orderId, {
        orderItems: orderItems,
        shippingAddress: order.shippingAddress,
        billingAddress: billingAddressObj,
        paymentMethod: order.paymentMethod,
        packingMaterial: order.packingMaterial,
        shippingMethod: order.shippingMethod,
        itemsPrice: orderItemsPrice,
        shippingPrice: order?.shippingPrice,
        packingMaterialPrice: order?.packingMaterialPrice,
        totalPrice: orderTotalPrice,
      })
    );
    //dispatch(listOrders());
    dispatch({ type: ORDER_DETAILS_RESET });
    dispatch(getOrderDetails(orderId));
  };
  const removeProduct = (id) => {
    setOrderItems((items) => items.filter((item) => item._id !== id));
  };
  return loading || loadingUpdate ? (
    <Loader />
  ) : error || errorUpdate ? (
    <Message variant="danger">
      {error}
      {errorUpdate}
    </Message>
  ) : (
    <>
      <Link to="/orderlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <Row>
        <Col md={8}>
          <h1>Order Edit #{customOrderId(order.createdAt)}</h1>
          <ListGroup variant="flush" className="order-d">
            <ListGroup.Item>
              <Form.Control
                type="text"
                name="search"
                placeholder="Search Products"
                onChange={optimisedVerion}
              />
            </ListGroup.Item>
            {products &&
              products.map((item, index) => {
                return (
                  <ListGroup.Item key={index}>
                    <Row>
                      <Col md={2}>
                        <Image
                          src={`${API_URL + item.image}`}
                          alt={item.name}
                          fluid
                          rounded
                        />
                      </Col>
                      <Col md={4}>
                        <Link to={`/products/${item.product}`}>
                          {item.name}
                        </Link>
                        <br />
                        Sold By: {item.seller?.sAlias}
                      </Col>
                      <Col md={4}>
                        {calculatePrice(item.price, item.discount, item.tax)}
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          variant="light"
                          onClick={(e) => addProductHandler(orderId, item._id)}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form onSubmit={submitHandler}>
            {orderItems.length === 0 ? (
              <Message>
                Order list is empty!. This order will be cancelled when updating
                this.
              </Message>
            ) : (
              <>
                <ListGroup variant="flush">
                  {orderItems.map((item) => {
                    return (
                      <ListGroup.Item key={item._id}>
                        <Row>
                          <Col md={2}>
                            <Image
                              src={`${API_URL + item.image}`}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Col>
                          <Col md={3}>
                            {item.name}
                            <br />
                            Sold By: {item.product?.seller?.sAlias}
                          </Col>
                          <Col md={2}>
                            {calculatePrice(
                              item.price,
                              item.discount,
                              item.tax
                            )}
                          </Col>
                          <Col md={3}>
                            <Form.Control
                              type="number"
                              min="1"
                              value={item.qty}
                              onChange={(e) =>
                                handleChange(
                                  item._id,
                                  item.product,
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                          <Col md={2}>
                            <Button
                              type="button"
                              variant="light"
                              onClick={() => {
                                const confirmBox = window.confirm(
                                  "Do you really want to delete this item?"
                                );
                                if (confirmBox === true) {
                                  removeProduct(item._id);
                                }
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
                <Row>
                  <Col md={{ span: 4, offset: 8 }}>
                    <b>Items Total *</b> : {orderItemsPrice}
                    <br />
                    <b>Total *</b> :{orderTotalPrice}
                  </Col>
                </Row>
              </>
            )}
            <br />
            <Button type="submit" variant="primary">
              Update
            </Button>
            {orderItems.length === 0 ? (
              <Button
                className="ml-2"
                type="button"
                variant="primary"
                onClick={() => {
                  dispatch(getOrderDetails(orderId));
                }}
              >
                Reset
              </Button>
            ) : (
              ""
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default OrderEditScreen;
