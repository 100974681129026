import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button, ListGroup, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { listProductDetails } from "../actions/productActions";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";
import { PRODUCT_IMAGE_UPDATE_RESET } from "../constants/productImageConstants";
import {
  listProductImages,
  createProductImage,
  updateProductImage,
  deleteProductImage,
  updateProductImageSingle,
} from "../actions/productImageActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { arrayMove } from "../utils/arrayMove";

const ProductGalleryScreen = ({ match, history }) => {
  const productId = match.params.id;
  const [productImageFiles, setProductImageFiles] = useState([]);
  const [imageDescription, setImageDescription] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    Promise.all(
      acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append("image", file);
        let response;
        try {
          response = await axios.post("/api/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } catch (err) {
          return err;
        }
        return response;
      })
    ).then((results) => {
      const fileData = results.map((file) => ({
        image: file.data,
        product: productId,
      }));
      dispatch(createProductImage(productId, fileData));
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });
  const dispatch = useDispatch();

  const productImageList = useSelector((state) => state.productImageList);
  const {
    loading: loadingProductImage,
    error: errorProductImage,
    success: successProductImage,
    productImages,
  } = productImageList;

  const productImageCreate = useSelector((state) => state.productImageCreate);
  const {
    loading: loadingProductImageCreate,
    error: errorProductImageCreate,
    success: successProductImageCreate,
  } = productImageCreate;

  const productImageUpdate = useSelector((state) => state.productImageUpdate);
  const {
    loading: loadingProductImageSingle,
    error: errorProductImageSingle,
    success: successProductImageSingle,
  } = productImageUpdate;

  const productImageDelete = useSelector((state) => state.productImageDelete);
  const {
    loading: loadingProductImageDelete,
    error: errorProductImageDelete,
    success: successProductImageDelete,
  } = productImageDelete;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  useEffect(() => {
    dispatch(listProductDetails(productId));
    dispatch(listProductImages(productId));
  }, []);

  useEffect(() => {
    if (!loadingProductImageDelete && successProductImageDelete) {
      dispatch(listProductImages(productId));
    }
  }, [successProductImageDelete, loadingProductImageDelete]);

  useEffect(() => {
    if (!loadingProductImageCreate && successProductImageCreate) {
      dispatch(listProductImages(productId));
    }
  }, [successProductImageCreate, loadingProductImageCreate]);

  useEffect(() => {
    setProductImageFiles(productImages);
  }, [productImages]);

  useEffect(() => {
    if (successProductImage) {
      dispatch({ type: PRODUCT_IMAGE_UPDATE_RESET });
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductImages(productId));
      }
    }
  }, [dispatch, history, productId, product, successProductImage]);

  const updateProductImageOrder = () => {
    const updatedProductImageFiles = productImageFiles.map(
      (productImage, index) => ({
        ...productImage,
        pOrder: index + 1,
      })
    );
    dispatch(updateProductImage({ productId, updatedProductImageFiles }));
  };

  const onDragEndAction = (param) => {
    const sourceIndex = param.source.index;
    const destinationIndex = param.destination?.index;
    if (destinationIndex) {
      setProductImageFiles(
        arrayMove(productImageFiles, sourceIndex, destinationIndex)
      );
    }
  };

  const removeImage = (e, productImageId) => {
    e.preventDefault();
    dispatch(deleteProductImage(productImageId));
  };

  const updateImageDescription = (inputId, event) => {
    event.preventDefault();
    const value = document.getElementById(`input-${inputId}`).value;
    dispatch(updateProductImageSingle(inputId, { name: value }));
  };

  return (
    <>
      <Link to="/productlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        {loadingProductImage && <Loader />}
        {loadingProductImageCreate && <Message>Uploading Images...</Message>}
        {errorProductImageCreate && <Message>Error Uploading Images.</Message>}
        {loadingProductImageSingle && <Message>Updating...</Message>}
        {errorProductImageSingle && <Message>Oops, Error!</Message>}
        {loadingProductImageDelete && <Message>Deleting Image...</Message>}
        {errorProductImageDelete && <Message>Error Deleting Image.</Message>}
        {errorProductImage && (
          <Message variant="danger">{errorProductImage}</Message>
        )}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <h4>{product.name}</h4>
            <h6 className={"mt-1 pt-0"}>Add Images</h6>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>

            <div>
              <DragDropContext onDragEnd={(param) => onDragEndAction(param)}>
                <ListGroup className="mt-2">
                  <h2>Re-order images</h2>
                  <Droppable droppableId="droppable-1">
                    {(provided, _) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {productImageFiles.map((productImage, i) => (
                          <Draggable
                            key={productImage._id}
                            draggableId={"draggable-" + productImage._id}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <ListGroup.Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: snapshot.isDragging
                                    ? "0 0 .4rem #666"
                                    : "none",
                                }}
                              >
                                <div className="clearfix v-align-parent">
                                  <div
                                    className="left p2 mr1 v-align-child"
                                    {...provided.dragHandleProps}
                                  >
                                    <i className="fas fa-bars" />
                                  </div>
                                  <div className="left p2 mr1 v-align-child">
                                    <Image
                                      width={60}
                                      src={`${axios.defaults.baseURL}${productImage.image}`}
                                    />
                                  </div>
                                  <div className="v-align-child">
                                    <Form>
                                      <Form.Row className="align-items-center">
                                        <Col xs="auto">
                                          <Form.Control
                                            className="mb-2"
                                            id={`input-${productImage._id}`}
                                            placeholder="Image Description"
                                            defaultValue={productImage.name}
                                          />
                                        </Col>
                                        <Col xs="auto">
                                          <Button
                                            className="mb-2"
                                            onClick={(e) =>
                                              updateImageDescription(
                                                productImage._id,
                                                e
                                              )
                                            }
                                          >
                                            Update
                                          </Button>
                                        </Col>
                                        <Col xs="auto">
                                          <Button
                                            type="submit"
                                            className="mb-2 remove-photo"
                                            onClick={(e) =>
                                              removeImage(e, productImage._id)
                                            }
                                          >
                                            <i
                                              className={"fas fa-times-circle"}
                                            />
                                          </Button>
                                        </Col>
                                      </Form.Row>
                                    </Form>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <div>
                          <Button
                            onClick={updateProductImageOrder}
                            type="submit"
                            className="my-2 block mx-auto"
                          >
                            Update Order
                          </Button>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </ListGroup>
              </DragDropContext>
            </div>
          </>
        )}
      </FormContainer>
    </>
  );
};

export default ProductGalleryScreen;
